import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "audio",
  id: "audio",
  src: "//ynyd2022.link-dev.com/bg.mp3",
  loop: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImgPreload = _resolveComponent("ImgPreload")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    onTouchmove: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.touchmove && _ctx.touchmove(...args))),
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args)))
  }, [
    _createVNode(_component_ImgPreload),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        (_openBlock(), _createBlock(_KeepAlive, null, [
          (_ctx.$route.name === 'map')
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { key: 0 }))
            : _createCommentVNode("", true)
        ], 1024)),
        (_openBlock(), _createBlock(_KeepAlive, null, [
          (_ctx.$route.name === 'mapMini')
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { key: 0 }))
            : _createCommentVNode("", true)
        ], 1024)),
        (_ctx.$route.name !== 'map' && _ctx.$route.name !== 'mapMini')
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("audio", _hoisted_1, null, 512)
  ], 32))
}