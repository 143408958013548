
import { Options, Vue } from "vue-class-component";
// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
import Ajax from "@/api/Ajax";

@Options({
  components: {},
  data() {
    return {
      li: [], // 列表
      ares: "昆明",
    };
  },
  mounted() {
    // 地区搜索
    if (this.$route.query.ares) {
      this.ares = this.$route.query.ares;
      this.getList();
    }
  },
  methods: {
    // 选中一个学校
    seletc(school: any) {
      this.$store.commit("setSchool", school);
      // 跳转到MniMap
      this.$router.replace({
        name: "mapMini",
        query: {
          ares: this.ares,
          id: school.id,
          name: school.name,
        },
      });
    },
    async getList() {
      const reData = await Ajax.request.post("/api/Pub/schoolList", {
        area: this.ares,
      });
      if (reData.data.code === 1) {
        this.li = reData.data.data;
        console.log(this.li);
      }
    },
  },
})
export default class SchoolList extends Vue {}
