<template>
  <div>
    <router-link replace :to="{ name: 'map' }" class="backBtn">
      返回
    </router-link>
    <img
      src="@/assets/Jihedi/title.png"
      class="titles"
      style="animation-delay: 0.2s"
    />
    <div class="listController">
      <div
        @click="jump(ind)"
        class="name animate__animated animate__fadeInUp"
        :class="`delay1-` + (ind + 1)"
        v-for="(val, ind) in list"
        :key="ind"
      >
        {{ val.name }}
      </div>
    </div>
    <Din type="jihedi">
      <img src="@/assets/D/6/1.webp" />
      <img src="@/assets/Jihedi/jihedi.png" />
    </Din>
  </div>
</template>
<script setup>
import Din from "@/components/Din.vue";
import JihediList from "./JihediList.js";
// 引入 router
import { useRouter } from "vue-router";
const router = useRouter();
const list = JihediList;
console.log(list);
function jump(ind) {
  // 跳转到haochi页面，不是push，是replace
  router.replace({
    name: "haochi",
    query: {
      ind: ind,
    },
  });
}
// 引入组件Din
</script>
<style lang="less" scoped>
.titles {
  width: 100%;
}
.listController {
  margin: auto;
  width: 220px;
  margin-top: -15%;
  height: calc(100vh - 230px);
  overflow-y: scroll;
  .name {
    background-color: #7dccf3;
    font-size: 20px;
    color: white;
    font-weight: bold;
    width: 220px;
    text-align: center;
    border-radius: 5px;
    // 除了最后一个，下边距10px
    padding: 5px 0px;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

@total-classes: 20; // 要生成的类的总数
@delay: 0.1s; // 动画延迟
.generate-classes(@i) when (@i > 0) {
  .generate-classes(@i - 1); // 递归调用，生成下一个类

  @class-name: ~"delay1-@{i}"; // 生成类名，如 "delay-1", "delay-2" 等

  .@{class-name} {
    animation-delay: (@i * @delay); // 设置动画延迟
  }
}
.generate-classes(@total-classes); // 开始生成类
</style>
