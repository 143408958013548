
import { Options, Vue } from "vue-class-component";
import { fabric } from "fabric";
import AnmList from "./AnmList";
@Options({
  emits: {
    end: "end",
  },
  props: {
    people: {
      type: String,
      default: "篮球",
    },
  },
  data() {
    return {
      endStatus: false, // 结束是否出发
      th: null, // 动画线程
      canvas: null, // 画板位置
      thisIndex: 0, // 当前动画的索引下标
      model: "站立", // 当前运行过程
      type: "右", // 面朝方向
      doLis: ["站立"], // 行为列表
      doIndex: 0, // 行为列表索引
      images1: AnmList.images1,
      images2: AnmList.images2,
    };
  },
  beforeUnmount() {
    clearInterval(this.th);
  },
  mounted() {
    this.init();
    // 启动动画
    this.th = setInterval(this.start, 20);
  },
  methods: {
    init() {
      let zom = 1;
      this.canvas = new fabric.Canvas(this.$refs.canvas, {
        width: 120,
        height: 120,
        // 不许人工绘制
        isDrawingMode: false,
        // 不许选中
        selection: false,
      });
      // 设置缩放
      this.canvas.setZoom(zom);
    },
    // 动画列表
    do(inLi: Array<string>) {
      // 下面是简化外部传入的参数的逻辑
      let li = [];
      for (let index = 0; index < inLi.length; index++) {
        const element = inLi[index];
        if (element === "开始" || element === "结束" || element === "站立") {
          continue;
        }
        if (element === "跑") {
          li.push("开始");
          li.push("跑");
          li.push("结束");
        } else {
          li.push(element);
        }
      }
      li.push("站立");
      // 计算整体进度
      let tempCount = 0;
      for (let index = 0; index < li.length; index++) {
        const element = li[index];
        let model = null;
        if (element === "站立") {
          continue;
          // model = "zhanli";
        } else if (element === "开始") {
          model = "kaishi";
        } else if (element === "跑") {
          model = "pao";
        } else if (element === "结束") {
          model = "jieshu";
        } else if (element === "左") {
          model = "zuo";
        } else if (element === "右") {
          model = "you";
        } else {
          model = "zhanli";
        }
        tempCount += this.images1[model].shang.length;
      }
      // 最小时间
      const miniTime = tempCount;
      console.log("最小时间", miniTime * 20 + "ms");
      // 计算进度
      let tpCpunt = 0;
      for (let index = 0; index < li.length; index++) {
        const element = li[index];
        let model = null;
        if (element === "站立") {
          continue;
          // model = "zhanli";
        } else if (element === "开始") {
          model = "kaishi";
        } else if (element === "跑") {
          model = "pao";
        } else if (element === "结束") {
          model = "jieshu";
        } else if (element === "左") {
          model = "zuo";
        } else if (element === "右") {
          model = "you";
        } else {
          model = "zhanli";
        }
        // 临时时间长度
        tpCpunt += this.images1[model].shang.length;
        const ps = (tpCpunt / miniTime) * 100;
        if (element === "开始" || element === "跑") {
          continue;
        }
        // 两位小数
        const ps2 = Math.round(ps * 100) / 100;
        console.log(element, ps2 + "%");
      }
      // 上面是计算进度
      this.doLis = li;
      this.doIndex = 0;
      this.endStatus = false;
      this.model = li[0];
      this.start();
    },
    // 开始动画
    start() {
      // 计算出当前的动画队列
      let tempType = null;
      if (this.model === "左" && this.type === "下") {
        tempType = "you";
      } else if (this.model === "左" && this.type === "右") {
        tempType = "shang";
      } else if (this.model === "左" && this.type === "上") {
        tempType = "zuo";
      } else if (this.model === "左" && this.type === "左") {
        tempType = "xia";
      } else if (this.type === "上") {
        tempType = "shang";
      } else if (this.type === "下") {
        tempType = "xia";
      } else if (this.type === "左") {
        tempType = "zuo";
      } else if (this.type === "右") {
        tempType = "you";
      } else {
        tempType = "you";
      }
      let model = null;
      if (this.model === "站立") {
        model = "zhanli";
      } else if (this.model === "开始") {
        model = "kaishi";
      } else if (this.model === "跑") {
        model = "pao";
      } else if (this.model === "结束") {
        model = "jieshu";
      } else if (this.model === "左") {
        model = "zuo";
      } else if (this.model === "右") {
        model = "you";
      } else {
        model = "zhanli";
      }
      // 列表名称
      let liName = "images1";
      if (this.people === "汉服") {
        liName = "images2";
      }
      let thisLi = this[liName][model][tempType];
      // 加载图片
      // 获取thisLi[this.thisIndex]的类型
      if (typeof thisLi[this.thisIndex] === "string") {
        fabric.Image.fromURL(thisLi[this.thisIndex], (oImg: any) => {
          this.canvas.clear();
          this.canvas.setBackgroundImage(oImg);
          thisLi[this.thisIndex] = oImg;
        });
      } else {
        this.canvas.clear();
        this.canvas.setBackgroundImage(thisLi[this.thisIndex]);
      }
      if (this.thisIndex < thisLi.length - 1) {
        this.thisIndex++;
      } else {
        // 动画索引列表更换
        this.thisIndex = 0;
        // 进行下一个动画的逻辑
        if (this.doIndex < this.doLis.length - 1) {
          let nextType = null;
          if (this.model === "右") {
            if (this.type === "下") {
              nextType = "左";
            } else if (this.type === "左") {
              nextType = "上";
            } else if (this.type === "上") {
              nextType = "右";
            } else if (this.type === "右") {
              nextType = "下";
            }
          } else if (this.model === "左") {
            if (this.type === "下") {
              nextType = "右";
            } else if (this.type === "右") {
              nextType = "上";
            } else if (this.type === "上") {
              nextType = "左";
            } else if (this.type === "左") {
              nextType = "下";
            }
          }
          if (nextType !== null) {
            this.type = nextType;
          }

          this.doIndex++;
          this.model = this.doLis[this.doIndex];
        } else if (this.endStatus === false) {
          // 发出结束的通知
          this.endStatus = true;
          console.log("结束动画");
          this.$emit("end");
        }
      }
    },
  },
})
export default class Animation extends Vue {}
