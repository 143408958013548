import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Start from "../views/Start.vue";
import Map from "../views/Map.vue";
import MapMini from "../views/MapMini.vue";
import City from "../views/City.vue";
import Kunming from "../views/Kunming.vue";
import SelectSchool from "../views/SelectSchool.vue";
import SchoolList from "../views/SchoolList.vue";
import Gonglue1 from "../views/Gonglue1.vue";
import Gonglue2 from "../views/Gonglue2.vue";
import Gonglue3 from "../views/Gonglue3.vue";
import Taocan from "../views/Taocan.vue";
import Jihe from "../views/Jihe.vue";
import Yanjiu from "../views/Yanjiu.vue";
import Select from "../views/Select.vue";
import Ran from "../views/Ran.vue";
import Dawei from "../views/Dawei.vue";
import YanjiuLong from "../views/YanjiuLong.vue";
import Share from "../views/Share.vue";
import Jihedi from "../views/Jihedi.vue";
import Haochi from "../views/Haochi.vue";
import Bbs from "../views/Bbs.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/start",
    name: "Start",
    component: Start,
  },
  {
    path: "/map",
    name: "map",
    component: Map,
  },
  {
    path: "/mapMini",
    name: "mapMini",
    component: MapMini,
  },
  {
    path: "/city",
    name: "city",
    component: City,
  },
  {
    path: "/kunming",
    name: "kunming",
    component: Kunming,
  },
  {
    path: "/selectSchool",
    name: "selectSchool",
    component: SelectSchool,
  },
  {
    path: "/schoolList",
    name: "schoolList",
    component: SchoolList,
  },
  {
    path: "/gonglue1",
    name: "gonglue1",
    component: Gonglue1,
  },
  {
    path: "/gonglue2",
    name: "gonglue2",
    component: Gonglue2,
  },
  {
    path: "/gonglue3",
    name: "gonglue3",
    component: Gonglue3,
  },
  {
    path: "/taocan",
    name: "taocan",
    component: Taocan,
  },
  {
    path: "/jihe",
    name: "jihe",
    component: Jihe,
  },
  {
    path: "/yanjiu",
    name: "yanjiu",
    component: Yanjiu,
  },
  {
    path: "/select",
    name: "select",
    component: Select,
  },
  {
    path: "/ran",
    name: "ran",
    component: Ran,
  },
  {
    path: "/dawei",
    name: "dawei",
    component: Dawei,
  },
  {
    path: "/yanjiuLong",
    name: "yanjiuLong",
    component: YanjiuLong,
  },
  {
    path: "/share",
    name: "share",
    component: Share,
  },
  {
    path: "/jihedi",
    name: "jihedi",
    component: Jihedi,
  },
  {
    path: "/haochi",
    name: "haochi",
    component: Haochi,
  },
  {
    path: "/bbs",
    name: "bbs",
    component: Bbs,
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
