
import { Options, Vue } from "vue-class-component";
// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
declare const wx: any;
import vuex from "vuex";
import Ajax from "@/api/Ajax";

@Options({
  components: {},
  computed: {
    ...vuex.mapState(["userinfo"]),
  },
  async mounted() {
    if (this.$route.query.token) {
      await this.tokenLogin(this.$route.query.token);
      this.$router.replace({
        name: "select",
      });
    }
    // 清空所有弹窗
    this.$store.commit("clearDin");
  },
  methods: {
    // 用户信息登录
    async tokenLogin(token: string) {
      const reData = await Ajax.request.post("/api/User/index", {
        token,
      });
      if (reData.data.code === 1) {
        this.$store.commit("setUserInfo", reData.data.data.userinfo);
      }
    },
    jump() {
      this.$router.replace({
        name: "select",
      });
      // if (!this.userinfo) {
      //   this.$store.dispatch("wxlogin");
      // } else {
      //   this.$router.replace({
      //     name: "select",
      //   });
      // }
    },
  },
})
export default class Start extends Vue {}
