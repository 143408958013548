export default class AnmList {
  public static images1 = {
    // 站立
    zhanli: {
      shang: [
        require("@/anm/篮球豹-上/qiufu-shang-0000.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0001.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0002.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0003.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0004.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0005.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0006.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0007.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0008.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0009.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0010.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0011.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0012.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0013.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0014.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0015.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0016.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0017.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0018.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0019.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0020.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0021.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0022.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0023.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0024.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0025.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0026.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0027.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0028.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0029.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0030.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0031.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0032.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0033.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0034.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0035.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0036.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0037.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0038.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0039.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0040.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0041.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0042.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0043.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0044.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0045.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0046.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0047.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0048.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0049.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0050.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0051.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0052.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0053.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0054.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0055.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0056.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0057.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0058.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0059.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0060.webp"),
      ],
      xia: [
        require("@/anm/篮球豹-下/qiufu-xia-0000.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0001.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0002.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0003.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0004.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0005.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0006.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0007.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0008.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0009.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0010.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0011.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0012.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0013.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0014.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0015.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0016.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0017.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0018.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0019.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0020.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0021.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0022.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0023.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0024.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0025.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0026.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0027.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0028.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0029.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0030.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0031.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0032.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0033.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0034.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0035.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0036.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0037.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0038.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0039.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0040.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0041.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0042.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0043.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0044.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0045.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0046.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0047.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0048.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0049.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0050.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0051.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0052.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0053.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0054.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0055.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0056.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0057.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0058.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0059.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0060.webp"),
      ],
      zuo: [
        require("@/anm/篮球豹-左/qiufu-zuo-0000.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0001.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0002.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0003.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0004.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0005.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0006.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0007.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0008.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0009.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0010.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0011.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0012.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0013.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0014.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0015.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0016.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0017.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0018.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0019.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0020.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0021.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0022.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0023.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0024.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0025.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0026.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0027.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0028.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0029.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0030.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0031.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0032.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0033.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0034.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0035.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0036.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0037.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0038.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0039.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0040.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0041.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0042.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0043.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0044.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0045.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0046.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0047.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0048.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0049.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0050.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0051.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0052.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0053.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0054.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0055.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0056.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0057.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0058.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0059.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0060.webp"),
      ],
      you: [
        require("@/anm/篮球豹-右/qiufu-you-0000.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0001.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0002.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0003.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0004.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0005.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0006.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0007.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0008.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0009.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0010.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0011.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0012.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0013.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0014.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0015.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0016.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0017.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0018.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0019.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0020.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0021.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0022.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0023.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0024.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0025.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0026.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0027.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0028.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0029.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0030.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0031.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0032.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0033.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0034.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0035.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0036.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0037.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0038.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0039.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0040.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0041.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0042.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0043.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0044.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0045.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0046.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0047.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0048.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0049.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0050.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0051.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0052.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0053.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0054.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0055.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0056.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0057.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0058.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0059.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0060.webp"),
      ],
    },
    // 开始
    kaishi: {
      shang: [
        require("@/anm/篮球豹-上/qiufu-shang-0065.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0066.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0067.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0068.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0069.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0070.webp"),
      ],
      xia: [
        require("@/anm/篮球豹-下/qiufu-xia-0065.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0066.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0067.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0068.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0069.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0070.webp"),
      ],
      zuo: [
        require("@/anm/篮球豹-左/qiufu-zuo-0065.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0066.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0067.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0068.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0069.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0070.webp"),
      ],
      you: [
        require("@/anm/篮球豹-右/qiufu-you-0065.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0066.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0067.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0068.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0069.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0070.webp"),
      ],
    },
    // 跑
    pao: {
      shang: [
        require("@/anm/篮球豹-上/qiufu-shang-0070.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0071.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0072.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0073.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0074.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0075.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0076.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0077.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0078.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0079.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0080.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0081.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0082.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0083.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0084.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0085.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0086.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0087.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0088.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0089.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0090.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0091.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0092.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0093.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0094.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0095.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0096.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0097.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0098.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0099.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0100.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0101.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0102.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0103.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0104.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0105.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0106.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0107.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0108.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0109.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0110.webp"),
      ],
      xia: [
        require("@/anm/篮球豹-下/qiufu-xia-0070.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0071.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0072.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0073.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0074.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0075.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0076.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0077.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0078.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0079.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0080.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0081.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0082.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0083.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0084.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0085.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0086.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0087.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0088.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0089.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0090.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0091.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0092.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0093.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0094.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0095.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0096.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0097.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0098.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0099.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0100.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0101.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0102.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0103.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0104.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0105.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0106.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0107.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0108.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0109.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0110.webp"),
      ],
      zuo: [
        require("@/anm/篮球豹-左/qiufu-zuo-0070.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0071.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0072.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0073.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0074.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0075.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0076.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0077.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0078.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0079.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0080.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0081.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0082.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0083.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0084.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0085.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0086.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0087.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0088.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0089.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0090.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0091.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0092.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0093.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0094.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0095.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0096.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0097.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0098.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0099.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0100.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0101.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0102.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0103.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0104.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0105.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0106.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0107.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0108.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0109.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0110.webp"),
      ],
      you: [
        require("@/anm/篮球豹-右/qiufu-you-0070.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0071.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0072.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0073.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0074.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0075.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0076.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0077.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0078.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0079.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0080.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0081.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0082.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0083.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0084.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0085.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0086.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0087.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0088.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0089.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0090.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0091.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0092.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0093.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0094.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0095.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0096.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0097.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0098.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0099.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0100.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0101.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0102.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0103.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0104.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0105.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0106.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0107.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0108.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0109.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0110.webp"),
      ],
    },
    // 结束
    jieshu: {
      shang: [
        require("@/anm/篮球豹-上/qiufu-shang-0111.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0112.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0113.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0114.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0115.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0116.webp"),
      ],
      xia: [
        require("@/anm/篮球豹-下/qiufu-xia-0111.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0112.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0113.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0114.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0115.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0116.webp"),
      ],
      zuo: [
        require("@/anm/篮球豹-左/qiufu-zuo-0111.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0112.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0113.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0114.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0115.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0116.webp"),
      ],
      you: [
        require("@/anm/篮球豹-右/qiufu-you-0111.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0112.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0113.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0114.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0115.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0116.webp"),
      ],
    },
    // 左
    zuo: {
      shang: [
        require("@/anm/篮球豹-上/qiufu-shang-0140.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0141.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0142.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0143.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0144.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0145.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0146.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0147.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0148.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0149.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0150.webp"),
      ],
      xia: [
        require("@/anm/篮球豹-下/qiufu-xia-0140.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0141.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0142.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0143.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0144.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0145.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0146.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0147.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0148.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0149.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0150.webp"),
      ],
      zuo: [
        require("@/anm/篮球豹-左/qiufu-zuo-0140.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0141.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0142.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0143.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0144.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0145.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0146.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0147.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0148.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0149.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0150.webp"),
      ],
      you: [
        require("@/anm/篮球豹-右/qiufu-you-0140.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0141.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0142.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0143.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0144.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0145.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0146.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0147.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0148.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0149.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0150.webp"),
      ],
    },
    // 右
    you: {
      shang: [
        require("@/anm/篮球豹-上/qiufu-shang-0120.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0121.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0122.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0123.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0124.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0125.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0126.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0127.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0128.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0129.webp"),
        require("@/anm/篮球豹-上/qiufu-shang-0130.webp"),
      ],
      xia: [
        require("@/anm/篮球豹-下/qiufu-xia-0120.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0121.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0122.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0123.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0124.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0125.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0126.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0127.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0128.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0129.webp"),
        require("@/anm/篮球豹-下/qiufu-xia-0130.webp"),
      ],
      zuo: [
        require("@/anm/篮球豹-左/qiufu-zuo-0120.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0121.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0122.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0123.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0124.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0125.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0126.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0127.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0128.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0129.webp"),
        require("@/anm/篮球豹-左/qiufu-zuo-0130.webp"),
      ],
      you: [
        require("@/anm/篮球豹-右/qiufu-you-0120.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0121.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0122.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0123.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0124.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0125.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0126.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0127.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0128.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0129.webp"),
        require("@/anm/篮球豹-右/qiufu-you-0130.webp"),
      ],
    },
  };

  public static images2 = {
    // 站立
    zhanli: {
      shang: [
        require("@/anm/汉服豹-上/hanfu-shang-0000.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0001.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0002.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0003.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0004.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0005.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0006.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0007.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0008.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0009.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0010.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0011.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0012.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0013.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0014.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0015.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0016.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0017.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0018.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0019.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0020.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0021.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0022.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0023.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0024.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0025.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0026.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0027.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0028.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0029.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0030.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0031.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0032.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0033.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0034.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0035.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0036.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0037.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0038.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0039.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0040.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0041.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0042.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0043.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0044.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0045.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0046.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0047.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0048.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0049.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0050.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0051.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0052.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0053.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0054.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0055.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0056.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0057.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0058.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0059.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0060.webp"),
      ],
      xia: [
        require("@/anm/汉服豹-下/hanfu-xia-0000.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0001.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0002.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0003.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0004.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0005.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0006.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0007.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0008.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0009.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0010.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0011.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0012.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0013.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0014.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0015.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0016.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0017.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0018.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0019.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0020.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0021.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0022.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0023.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0024.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0025.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0026.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0027.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0028.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0029.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0030.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0031.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0032.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0033.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0034.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0035.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0036.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0037.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0038.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0039.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0040.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0041.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0042.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0043.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0044.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0045.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0046.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0047.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0048.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0049.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0050.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0051.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0052.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0053.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0054.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0055.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0056.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0057.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0058.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0059.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0060.webp"),
      ],
      zuo: [
        require("@/anm/汉服豹-左/hanfu-zuo-0000.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0001.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0002.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0003.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0004.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0005.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0006.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0007.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0008.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0009.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0010.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0011.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0012.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0013.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0014.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0015.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0016.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0017.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0018.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0019.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0020.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0021.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0022.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0023.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0024.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0025.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0026.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0027.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0028.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0029.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0030.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0031.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0032.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0033.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0034.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0035.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0036.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0037.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0038.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0039.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0040.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0041.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0042.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0043.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0044.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0045.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0046.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0047.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0048.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0049.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0050.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0051.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0052.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0053.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0054.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0055.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0056.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0057.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0058.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0059.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0060.webp"),
      ],
      you: [
        require("@/anm/汉服豹-右/hanfu-you-0000.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0001.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0002.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0003.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0004.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0005.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0006.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0007.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0008.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0009.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0010.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0011.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0012.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0013.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0014.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0015.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0016.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0017.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0018.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0019.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0020.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0021.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0022.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0023.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0024.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0025.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0026.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0027.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0028.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0029.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0030.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0031.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0032.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0033.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0034.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0035.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0036.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0037.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0038.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0039.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0040.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0041.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0042.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0043.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0044.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0045.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0046.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0047.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0048.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0049.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0050.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0051.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0052.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0053.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0054.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0055.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0056.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0057.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0058.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0059.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0060.webp"),
      ],
    },
    // 开始
    kaishi: {
      shang: [
        require("@/anm/汉服豹-上/hanfu-shang-0065.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0066.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0067.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0068.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0069.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0070.webp"),
      ],
      xia: [
        require("@/anm/汉服豹-下/hanfu-xia-0065.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0066.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0067.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0068.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0069.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0070.webp"),
      ],
      zuo: [
        require("@/anm/汉服豹-左/hanfu-zuo-0065.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0066.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0067.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0068.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0069.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0070.webp"),
      ],
      you: [
        require("@/anm/汉服豹-右/hanfu-you-0065.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0066.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0067.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0068.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0069.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0070.webp"),
      ],
    },
    // 跑
    pao: {
      shang: [
        require("@/anm/汉服豹-上/hanfu-shang-0070.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0071.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0072.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0073.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0074.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0075.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0076.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0077.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0078.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0079.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0080.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0081.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0082.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0083.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0084.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0085.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0086.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0087.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0088.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0089.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0090.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0091.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0092.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0093.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0094.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0095.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0096.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0097.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0098.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0099.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0100.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0101.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0102.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0103.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0104.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0105.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0106.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0107.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0108.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0109.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0110.webp"),
      ],
      xia: [
        require("@/anm/汉服豹-下/hanfu-xia-0070.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0071.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0072.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0073.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0074.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0075.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0076.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0077.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0078.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0079.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0080.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0081.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0082.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0083.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0084.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0085.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0086.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0087.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0088.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0089.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0090.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0091.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0092.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0093.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0094.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0095.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0096.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0097.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0098.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0099.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0100.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0101.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0102.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0103.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0104.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0105.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0106.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0107.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0108.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0109.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0110.webp"),
      ],
      zuo: [
        require("@/anm/汉服豹-左/hanfu-zuo-0070.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0071.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0072.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0073.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0074.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0075.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0076.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0077.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0078.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0079.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0080.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0081.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0082.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0083.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0084.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0085.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0086.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0087.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0088.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0089.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0090.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0091.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0092.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0093.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0094.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0095.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0096.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0097.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0098.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0099.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0100.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0101.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0102.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0103.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0104.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0105.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0106.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0107.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0108.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0109.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0110.webp"),
      ],
      you: [
        require("@/anm/汉服豹-右/hanfu-you-0070.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0071.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0072.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0073.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0074.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0075.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0076.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0077.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0078.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0079.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0080.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0081.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0082.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0083.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0084.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0085.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0086.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0087.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0088.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0089.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0090.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0091.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0092.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0093.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0094.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0095.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0096.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0097.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0098.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0099.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0100.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0101.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0102.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0103.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0104.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0105.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0106.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0107.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0108.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0109.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0110.webp"),
      ],
    },
    // 结束
    jieshu: {
      shang: [
        require("@/anm/汉服豹-上/hanfu-shang-0111.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0112.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0113.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0114.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0115.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0116.webp"),
      ],
      xia: [
        require("@/anm/汉服豹-下/hanfu-xia-0111.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0112.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0113.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0114.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0115.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0116.webp"),
      ],
      zuo: [
        require("@/anm/汉服豹-左/hanfu-zuo-0111.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0112.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0113.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0114.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0115.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0116.webp"),
      ],
      you: [
        require("@/anm/汉服豹-右/hanfu-you-0111.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0112.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0113.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0114.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0115.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0116.webp"),
      ],
    },
    // 左
    zuo: {
      shang: [
        require("@/anm/汉服豹-上/hanfu-shang-0140.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0141.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0142.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0143.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0144.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0145.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0146.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0147.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0148.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0149.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0150.webp"),
      ],
      xia: [
        require("@/anm/汉服豹-下/hanfu-xia-0140.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0141.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0142.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0143.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0144.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0145.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0146.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0147.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0148.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0149.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0150.webp"),
      ],
      zuo: [
        require("@/anm/汉服豹-左/hanfu-zuo-0140.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0141.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0142.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0143.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0144.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0145.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0146.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0147.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0148.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0149.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0150.webp"),
      ],
      you: [
        require("@/anm/汉服豹-右/hanfu-you-0140.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0141.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0142.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0143.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0144.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0145.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0146.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0147.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0148.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0149.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0150.webp"),
      ],
    },
    // 右
    you: {
      shang: [
        require("@/anm/汉服豹-上/hanfu-shang-0120.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0121.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0122.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0123.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0124.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0125.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0126.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0127.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0128.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0129.webp"),
        require("@/anm/汉服豹-上/hanfu-shang-0130.webp"),
      ],
      xia: [
        require("@/anm/汉服豹-下/hanfu-xia-0120.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0121.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0122.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0123.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0124.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0125.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0126.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0127.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0128.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0129.webp"),
        require("@/anm/汉服豹-下/hanfu-xia-0130.webp"),
      ],
      zuo: [
        require("@/anm/汉服豹-左/hanfu-zuo-0120.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0121.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0122.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0123.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0124.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0125.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0126.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0127.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0128.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0129.webp"),
        require("@/anm/汉服豹-左/hanfu-zuo-0130.webp"),
      ],
      you: [
        require("@/anm/汉服豹-右/hanfu-you-0120.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0121.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0122.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0123.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0124.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0125.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0126.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0127.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0128.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0129.webp"),
        require("@/anm/汉服豹-右/hanfu-you-0130.webp"),
      ],
    },
  };
}
