
import { Options, Vue } from "vue-class-component";
// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
import Animation from "@/components/canvas/Animation.vue";
import Ajax from "@/api/Ajax";
import Din from "@/components/Din.vue";
declare const LA: any;
@Options({
  components: {
    Animation,
    Din,
  },
  computed: {
    className() {
      let startName = ["animo"];
      if (this.nowAnm) {
        startName.push(this.nowAnm.name);
      }
      return startName;
    },
  },
  data() {
    return {
      url: null, // iframe 地址
      modal: false, // 网盘
      stopStyle: "left:58.356vw;bottom:7.485vw;", // 禁止时候的坐标
      nickname: "豹豹", // 当前昵称
      people: "汉服", // 当前显示的分类
      nodeIndex: "4", // 当前所在区域
      canClick: true, // 是否能够点击，动画运动过程中不能点击
      nowAnm: null, // 当前CSS3动画对象
      ares: null, // 学校名称
      id: null, // 学校ID
      info: null, // 学校详情
      pList: {
        "43": {
          name: "mv43", // 动画名称
          direction: "normal", // 播放方向
          s: 1.28, // 播放速度
          doLi: ["左", "跑", "右"], // 执行动作
        },
        "42": {
          name: "mv42", // 动画名称
          direction: "normal", // 播放方向
          s: 4.06, // 播放速度
          doLi: ["左", "跑", "右", "跑", "右", "跑", "左"], // 执行动作
        },
        "41": {
          name: "mv41", // 动画名称
          direction: "normal", // 播放方向
          s: 2.56, // 播放速度
          doLi: ["左", "跑", "右", "跑"],
        },
        //
        "34": {
          name: "mv34", // 动画名称
          direction: "normal", // 播放方向
          s: 4.06, // 播放速度
          doLi: ["右", "跑", "左"],
        },
        "32": {
          name: "mv32", // 动画名称
          direction: "normal", // 播放方向
          s: 4.06, // 播放速度
          doLi: ["左", "跑", "右", "跑", "右", "跑", "左"], // 执行动作
        },
        "31": {
          name: "mv31", // 动画名称
          direction: "normal", // 播放方向
          s: 2.56, // 播放速度
          doLi: ["左", "跑", "右", "跑"],
        },
        //
        "24": {
          name: "mv24", // 动画名称
          direction: "normal", // 播放方向
          s: 4.06, // 播放速度
          doLi: ["左", "跑", "左", "跑", "左", "跑", "左"],
        },
        "23": {
          name: "mv23", // 动画名称
          direction: "normal", // 播放方向
          s: 4.06, // 播放速度
          doLi: ["左", "跑", "左", "跑", "左", "跑", "左"],
        },
        "21": {
          name: "mv21", // 动画名称
          direction: "normal", // 播放方向
          s: 1.5, // 播放速度
          doLi: ["左", "跑", "右"],
        },
        //
        "12": {
          name: "mv12", // 动画名称
          direction: "normal", // 播放方向
          s: 1.5, // 播放速度
          doLi: ["右", "跑", "左"],
        },
        "13": {
          name: "mv13", // 动画名称
          direction: "normal", // 播放方向
          s: 3, // 播放速度
          doLi: ["左", "左", "跑", "左", "跑", "左"],
        },
        "14": {
          name: "mv14", // 动画名称
          direction: "normal", // 播放方向
          s: 3, // 播放速度
          doLi: ["左", "左", "跑", "左", "跑", "左"],
        },
      },
    };
  },
  activated() {
    // 状态管理中直接加载数据源
    this.people = this.$store.state.people;
    this.nickname = this.$store.state.peopleName;
    // 加载数据
    if (this.$route.query.ares) {
      this.ares = this.$route.query.ares;
      this.id = this.$route.query.id;
      this.getInfo();
    }
  },
  methods: {
    back() {
      // 判断localStorage中是否存在prevRoute，存在就replace到缓存，否则就replace到name=map的页面
      this.$router.replace({
        name: "schoolList",
        query: {
          ares: this.ares,
        },
      });
    },
    // 跳转到周边页面
    zhoubian() {
      // 跳转到haochi页面，带参数school
      this.$router.replace({
        name: "haochi",
        query: {
          school: this.info.name,
        },
      });
    },
    // 跳转
    async to(toIndex: string) {
      if (!this.canClick) {
        return false;
      }
      const key = `${this.nodeIndex}${toIndex}`;
      console.log(key);
      // 判断是否需要执行东海
      if (this.pList[key]) {
        this.nodeIndex = toIndex;
        this.canClick = false; // 禁止点击
        // 获取动画
        const element = this.pList[key];
        this.stopStyle = "";
        this.nowAnm = element;
        // 设置3D动画
        this.$refs.animo2.do(element.doLi);
        // 等待这一段动画结束
        await new Promise<void>((reverse) => {
          setTimeout(() => {
            reverse();
          }, element.s * 1000);
        });
        this.canClick = true; // 禁止点击
        // 获取ID是animo距离屏幕左边和上边的值
        const { left, top } = this.$refs.animo2.$el.getBoundingClientRect();
        this.stopStyle = {
          left: left + "px",
          top: top + "px",
        };
        this.nowAnm = null; // 清空动画
        setTimeout(() => {
          this.jump(toIndex);
        }, 200);
      } else {
        this.canClick = true; // 禁止点击
        this.nowAnm = null; // 清空动画
        setTimeout(() => {
          this.jump(toIndex);
        }, 200);
      }
    },
    jump(toIndex: string) {
      let url;
      let ifm = false;
      if (toIndex === "2") {
        // 新窗口打开地址
        url = this.info.xuzhi_url;
        ifm = true;
        try {
          LA.track("ruxuexuzhi", {
            ares: this.ares,
          });
        } catch (error) {
          console.log(error);
        }
      } else if (toIndex === "1") {
        this.zhoubian();
      } else if (toIndex === "3") {
        // 新窗口打开地址
        url = this.info.liaojie_url;
        ifm = true;
        try {
          LA.track("liaojiexuexiao", {
            ares: this.ares,
          });
        } catch (error) {
          console.log(error);
        }
      } else if (toIndex === "4") {
        // 新窗口打开地址
        url = this.info.vr_url;
        try {
          LA.track("vr", {
            ares: this.ares,
          });
        } catch (error) {
          console.log(error);
        }
      }
      if (toIndex !== "1") {
        if (url) {
          // if (ifm) {
          //   this.url = url;
          //   this.modal = true;
          // } else {
          //   window.open(url);
          // }
          // 直接改成都跳转的了
          window.open(url);
        } else {
          this.$Notice.warning({
            title: "暂未上传内容",
          });
        }
      }
    },
    // 获得详情
    async getInfo() {
      const reData = await Ajax.request.post("/api/Pub/schoolDetail", {
        id: this.id,
      });
      if (reData.data.code === 1) {
        this.info = reData.data.data;
        console.log(this.info);
      }
    },
  },
})
export default class TreeD extends Vue {}
