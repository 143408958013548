
import { Options, Vue } from "vue-class-component";
// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
import Animation from "@/components/canvas/Animation.vue";
import Din from "@/components/Din.vue";
declare const LA: any;
@Options({
  name: "map",
  components: {
    Animation,
    Din,
  },
  computed: {
    className() {
      let startName = ["animo"];
      if (this.nowAnm) {
        startName.push(this.nowAnm.name);
      }
      return startName;
    },
  },
  data() {
    return {
      stopStyle: "left:50.644vw;bottom:19.485vw;", // 禁止时候的坐标
      nickname: "豹豹", // 当前昵称
      people: "汉服", // 当前显示的分类
      nodeIndex: "0", // 当前所在区域
      canClick: true, // 是否能够点击，动画运动过程中不能点击
      nowAnm: null, // 当前CSS3动画对象
      // 动画组列表
      pList: {
        "01": {
          name: "mv01", // 动画名称
          direction: "normal", // 播放方向
          s: 6.5, // 播放速度
          doLi: [
            "开始",
            "跑",
            "结束",
            "左",
            "开始",
            "跑",
            "结束",
            "右",
            "开始",
            "跑",
            "结束",
            "开始",
            "跑",
            "结束",
            "左",
            "开始",
            "跑",
            "跑",
            "结束",
            "站立",
          ],
        },
        "02": {
          name: "mv02", // 动画名称
          direction: "normal", // 播放方向
          s: 8, // 播放速度
          doLi: [
            "开始",
            "跑",
            "结束",
            "左",
            "开始",
            "跑",
            "结束",
            "右",
            "开始",
            "跑",
            "结束",
            "开始",
            "跑",
            "结束",
            "左",
            "开始",
            "跑",
            "结束",
            "左",
            "开始",
            "跑",
            "跑",
            "结束",
            "站立",
          ],
        },
        "03": {
          name: "mv03", // 动画名称
          direction: "normal", // 播放方向
          s: 6, // 播放速度
          doLi: [
            "开始",
            "跑",
            "结束",
            "左",
            "开始",
            "跑",
            "结束",
            "右",
            "开始",
            "跑",
            "结束",
            "左",
            "开始",
            "跑",
            "结束",
            "左",
            "开始",
            "跑",
            "结束",
            "站立",
          ],
        },
        "04": {
          name: "mv04", // 动画名称
          direction: "normal", // 播放方向
          s: 2, // 播放速度
          doLi: ["开始", "跑", "结束", "左", "开始", "跑", "结束", "站立"],
        },
        "05": {
          name: "mv05", // 动画名称
          direction: "normal", // 播放方向
          s: 6, // 播放速度
          doLi: [
            "开始",
            "跑",
            "结束",
            "左",
            "开始",
            "跑",
            "结束",
            "右",
            "开始",
            "跑",
            "结束",
            "开始",
            "跑",
            "结束",
            "左",
            "开始",
            "跑",
            "结束",
            "站立",
          ],
        },
        //
        "12": {
          name: "mv12", // 动画名称
          direction: "normal", // 播放方向
          s: 2.78, // 播放速度
          doLi: ["左", "左", "跑", "右", "跑"],
        },
        "15": {
          name: "mv15", // 动画名称
          direction: "normal", // 播放方向
          s: 1.5, // 播放速度
          doLi: ["左", "左", "跑"],
        },
        "13": {
          name: "mv13", // 动画名称
          direction: "normal", // 播放方向
          s: 5.34, // 播放速度
          doLi: ["左", "左", "跑", "右", "跑", "右", "跑", "左", "跑"],
        },
        "14": {
          name: "mv14", // 动画名称
          direction: "normal", // 播放方向
          s: 4.06, // 播放速度
          doLi: ["左", "左", "跑", "右", "跑", "左", "跑"],
        },
        //
        "21": {
          name: "mv21", // 动画名称
          direction: "normal", // 播放方向
          s: 2.78, // 播放速度
          doLi: ["左", "左", "跑", "左", "跑"],
        },
        "25": {
          name: "mv25", // 动画名称
          direction: "normal", // 播放方向
          s: 2.78, // 播放速度
          doLi: ["左", "左", "跑", "右", "跑"],
        },
        "23": {
          name: "mv23", // 动画名称
          direction: "normal", // 播放方向
          s: 6.62, // 播放速度
          doLi: [
            "左",
            "左",
            "跑",
            "右",
            "跑",
            "右",
            "跑",
            "右",
            "跑",
            "左",
            "跑",
          ],
        },
        "24": {
          name: "mv24", // 动画名称
          direction: "normal", // 播放方向
          s: 5.34, // 播放速度
          doLi: [
            "左",
            "左",
            "跑",
            "右",
            "跑",
            "右",
            "跑",
            "左",
            "跑",
            "右",
            "右",
          ],
        },
        //
        "31": {
          name: "mv31", // 动画名称
          direction: "normal", // 播放方向
          s: 5.34, // 播放速度
          doLi: ["左", "左", "跑", "右", "跑", "左", "跑", "左", "跑"],
        },
        "32": {
          name: "mv32", // 动画名称
          direction: "normal", // 播放方向
          s: 6.62, // 播放速度
          doLi: [
            "左",
            "左",
            "跑",
            "右",
            "跑",
            "左",
            "跑",
            "左",
            "跑",
            "左",
            "跑",
          ],
        },
        "35": {
          name: "mv35", // 动画名称
          direction: "normal", // 播放方向
          s: 5.34, // 播放速度
          doLi: ["左", "左", "跑", "右", "跑", "左", "跑", "左", "跑"],
        },
        "34": {
          name: "mv34", // 动画名称
          direction: "normal", // 播放方向
          s: 5.34, // 播放速度
          doLi: ["左", "左", "跑", "右", "跑", "右", "跑", "左", "跑"],
        },
        //
        "41": {
          name: "mv41", // 动画名称
          direction: "normal", // 播放方向
          s: 3.84, // 播放速度
          doLi: ["左", "跑", "右", "跑", "左", "跑"],
        },
        "42": {
          name: "mv42", // 动画名称
          direction: "normal", // 播放方向
          s: 5.12, // 播放速度
          doLi: ["左", "跑", "右", "跑", "左", "跑", "左", "跑"],
        },
        "43": {
          name: "mv43", // 动画名称
          direction: "normal", // 播放方向
          s: 5.12, // 播放速度
          doLi: ["左", "跑", "右", "跑", "左", "跑", "左", "跑"],
        },
        "45": {
          name: "mv45", // 动画名称
          direction: "normal", // 播放方向
          s: 3.84, // 播放速度
          doLi: ["左", "跑", "右", "跑", "左", "跑"],
        },
        //
        "51": {
          name: "mv51", // 动画名称
          direction: "normal", // 播放方向
          s: 1.06, // 播放速度
          doLi: ["跑"],
        },
        "52": {
          name: "mv52", // 动画名称
          direction: "normal", // 播放方向
          s: 2.34, // 播放速度
          doLi: ["跑", "左", "跑"],
        },
        "53": {
          name: "mv53", // 动画名称
          direction: "normal", // 播放方向
          s: 5.34, // 播放速度
          doLi: ["跑", "右", "跑", "右", "跑", "左", "跑"],
        },
        "54": {
          name: "mv54", // 动画名称
          direction: "normal", // 播放方向
          s: 4.06, // 播放速度
          doLi: ["右", "右", "跑", "右", "跑", "左", "跑"],
        },
      },
    };
  },
  mounted() {
    // 昵称和类型赋值
    if (this.$route.query.nickname) {
      this.nickname = this.$route.query.nickname;
      this.$store.commit("setNickname", this.nickname);
    }
    if (this.$route.query.type) {
      this.people = this.$route.query.type;
      this.$store.commit("setPeople", this.people);
    }
  },
  methods: {
    async to(toIndex: string, urlName: string) {
      try {
        if (urlName === "dawei") {
          LA.track("indawei");
        } else if (urlName === "ran") {
          LA.track("injihe");
        } else if (urlName === "selectSchool") {
          LA.track("inkanchayuan");
        } else if (urlName === "yanjiu") {
          LA.track("inhaowan");
        } else if (urlName === "taocan") {
          LA.track("infulishe");
        }
      } catch (error) {
        console.log(error);
      }

      if (!this.canClick) {
        return false;
      }
      const key = `${this.nodeIndex}${toIndex}`;
      console.log("当前", this.nodeIndex, toIndex, this.$refs.animo.type);
      // 判断是否需要额外处理朝向
      if (
        (this.nodeIndex === "5" &&
          toIndex === "3" &&
          this.$refs.animo.type === "上") ||
        (this.nodeIndex === "5" &&
          toIndex === "4" &&
          this.$refs.animo.type === "下")
      ) {
        // 转一次需要的时间周期
        this.$refs.animo.do(["右"]);
        await new Promise<void>((reverse) => {
          setTimeout(() => {
            reverse();
          }, 220);
        });
        this.$refs.animo.do(["右"]);
        await new Promise<void>((reverse) => {
          setTimeout(() => {
            reverse();
          }, 220);
        });
      } else if (this.nodeIndex === "4" && this.$refs.animo.type === "上") {
        // 转一次需要的时间周期
        this.$refs.animo.do(["右"]);
        await new Promise<void>((reverse) => {
          setTimeout(() => {
            reverse();
          }, 220);
        });
      } else if (this.nodeIndex === "4" && this.$refs.animo.type === "下") {
        // 转一次需要的时间周期
        this.$refs.animo.do(["左"]);
        await new Promise<void>((reverse) => {
          setTimeout(() => {
            reverse();
          }, 220);
        });
      }
      console.log(key, this.pList[key]);
      // 判断是否需要执行东海
      if (this.pList[key]) {
        this.nodeIndex = toIndex;
        this.canClick = false; // 禁止点击
        // 获取动画
        const element = this.pList[key];
        this.stopStyle = "";
        this.nowAnm = element;
        // 设置3D动画
        this.$refs.animo.do(element.doLi);
        // 等待这一段动画结束
        await new Promise<void>((reverse) => {
          setTimeout(() => {
            reverse();
          }, element.s * 1000);
        });
        this.canClick = true; // 禁止点击
        // 获取ID是animo距离屏幕左边和上边的值
        const { left, top } = this.$refs.animo.$el.getBoundingClientRect();
        this.stopStyle = {
          left: left + "px",
          top: top + "px",
        };
        this.nowAnm = null; // 清空动画
        setTimeout(() => {
          // 判断是大卫
          if ("dawei" == urlName) {
            window.location.replace(
              "https://wap.yn.10086.cn/act2020/act23/1689coupon/index"
            );
          } else if ("jihedi" == urlName) {
            this.$router.replace({
              name: "bbs",
            });
          } else {
            this.$router.replace({
              name: urlName,
            });
          }
        }, 200);
      } else {
        this.canClick = true; // 禁止点击
        this.nowAnm = null; // 清空动画
        setTimeout(() => {
          if ("dawei" == urlName) {
            window.location.replace(
              "https://wap.yn.10086.cn/act2020/act23/1689coupon/index"
            );
          } else if ("jihedi" == urlName) {
            this.$router.replace({
              name: "bbs",
            });
          } else {
            this.$router.replace({
              name: urlName,
            });
          }
        }, 200);
      }
    },
  },
})
export default class TreeD extends Vue {}
