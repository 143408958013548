
import { Options, Vue } from "vue-class-component";
import Din from "@/components/Din.vue";
// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src

@Options({
  components: {
    Din,
  },
  methods: {},
  mounted() {
    setTimeout(() => {
      const audio: any = document.getElementById("audio");
      if (!audio.paused) {
        audio.pause();
      }
    }, 1000);
  },
})
export default class Gonglue1 extends Vue {}
