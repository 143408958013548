
import { Options, Vue } from "vue-class-component";
// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src

@Options({
  components: {},
  methods: {
    jump() {
      this.$router.replace({
        name: "city",
      });
    },
  },
})
export default class SelectSchool extends Vue {}
