<template>
  <div class="listPage">
    <img
      src="@/assets/Map/logo@3x.webp"
      class="logo animate__animated animate__zoomIn"
      style="animation-delay: 0.2s"
    />
    <router-link replace :to="{ name: 'map' }" class="backBtn">
      返回
    </router-link>
    <div style="height: 50px"></div>
    <img src="@/assets/Haochi/haochi.png" class="img1" />
    <div style="height: 10px"></div>
    <div v-if="info" class="textList right">
      <div
        v-for="(val, ind) in tag1s"
        :key="ind"
        class="aTextList animate__animated animate__fadeInRight"
        @click="jump(val.url)"
        :class="'delay2-' + (ind + 1)"
      >
        {{ val.tag }}
      </div>
    </div>
    <img src="@/assets/Haochi/haowan.png" class="img2" />
    <div style="height: 10px"></div>
    <div v-if="info" class="textList">
      <div
        v-for="(val, ind) in tag2s"
        :key="ind"
        class="aTextList animate__animated animate__fadeInLeft"
        @click="jump(val.url)"
        :class="'delay2-' + (ind + tag1s.length)"
      >
        {{ val.tag }}
      </div>
    </div>
  </div>
</template>
<script setup>
// 引入ref
import { ref } from "vue";
// 引入声明周期函数，模版渲染之前
import { onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import JihediList from "./JihediList.js";

const route = useRoute();
let school = route.query.school;
// 循环数组JihediList找出每个元素中schools这个数组中的每个字符串是否包含school
let info = ref(null);
for (let i = 0; i < JihediList.length; i++) {
  for (let j = 0; j < JihediList[i].schools.length; j++) {
    // 替换两个参数的中文括号为英文
    JihediList[i].schools[j] = JihediList[i].schools[j].replace("（", "(");
    JihediList[i].schools[j] = JihediList[i].schools[j].replace("）", ")");
    school = school.replace("（", "(");
    school = school.replace("）", ")");
    if (
      JihediList[i].schools[j].includes(school) ||
      school.includes(JihediList[i].schools[j])
    ) {
      console.log(school, JihediList[i].schools[j]);
      info.value = JihediList[i];
      break;
    }
  }
  if (info.value) {
    break;
  }
}
let tag1s = ref([]);
let tag2s = ref([]);
// 把info.value.tag1s从对象转换为数组
for (let i in info.value.tag1s) {
  tag1s.value.push(info.value.tag1s[i]);
}
// 把info.value.tag2s从对象转换为数组
for (let i in info.value.tag2s) {
  tag2s.value.push(info.value.tag2s[i]);
}
function jump(jump) {
  if (jump) {
    window.location.href = jump;
  }
}
</script>
<style lang="less" scoped>
.listPage {
  background-image: url("../assets/Haochi/bj.png");
  // 100%
  background-size: 100% 100%;
  // 最小高度是100vh
  min-height: 100vh;
  padding-bottom: 50px;
}
.img1 {
  width: 80%;
  display: block;
  margin: auto;
}
.img2 {
  width: 70%;
  display: block;
  margin: auto;
}
.img3 {
  display: block;
  width: 90%;
  margin: auto;
  // 吸住浏览器底部
  position: fixed;
  bottom: 0;
  left: 5%;
}
.textList {
  display: flex;
  // 允许换行
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
  .aTextList {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    border: 5px solid #000000;
    border-top: 0px;
    border-radius: 20px;
    padding: 0px 10px;
    background-color: white;
    // 左边距0右边距10
    margin-left: 0px;
    margin-right: 10px;
    margin-bottom: 5px;
  }
}
.right {
  justify-content: flex-end;
  .aTextList {
    // 右边距0左边距10
    margin-right: 0px;
    margin-left: 10px;
  }
}
.logo {
  z-index: 2;
  width: 15vw;
  position: absolute;
  top: 20px;
  left: 20px;
}

@total-classes: 20; // 要生成的类的总数
@delay: 0.1s; // 动画延迟
.generate-classes(@i) when (@i > 0) {
  .generate-classes(@i - 1); // 递归调用，生成下一个类

  @class-name: ~"delay2-@{i}"; // 生成类名，如 "delay-1", "delay-2" 等

  .@{class-name} {
    animation-delay: (@i * @delay); // 设置动画延迟
  }
}
.generate-classes(@total-classes); // 开始生成类
</style>
