
import { Options, Vue } from "vue-class-component";

@Options({
  computed: {},
  props: {
    // 要预加载的图片
    li: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      longNumber: 0, // 加载成功的图片
    };
  },
  mounted() {
    setTimeout(this.build, 1000);
  },
  methods: {
    // 等待一个图的加载过程
    imgPreloader(url: any) {
      return new Promise<void>((resolve, reject) => {
        let image = new Image();
        image.onload = () => {
          this.longNumber++;
          resolve();
        };
        image.onerror = () => {
          reject();
        };
        image.src = url;
      });
    },
    // 加载所有图片
    async build() {
      let arr = [];
      for (let index = 0; index < this.li.length; index++) {
        const element = this.li[index];
        arr.push(this.imgPreloader(element));
      }
      await Promise.all(arr);
      this.$emit("end");
    },
  },
})
export default class ImgPreload extends Vue {}
