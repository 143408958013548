
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    type: String,
  },
  data() {
    return {
      show: true,
      disable: true,
    };
  },
  mounted() {
    // 一个周期内只显示一次
    if (this.$store.state.din[this.type]) {
      this.disable = false;
    }
  },
  methods: {
    // 关闭界面
    guanbi() {
      this.show = false;
      setTimeout(() => {
        this.disable = false;
        this.$store.commit("setDin", this.type);
      }, 1000);
    },
  },
})
export default class Din extends Vue {}
