
import { Options, Vue } from "vue-class-component";
// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
import Din from "@/components/Din.vue";
@Options({
  components: {
    Din,
  },
  methods: {},
})
export default class Taocan extends Vue {}
