import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "view-ui-plus/dist/styles/viewuiplus.css";
import "animate.css";
import ViewUIPlus from "view-ui-plus";

createApp(App).use(ViewUIPlus).use(store).use(router).mount("#app");
