<template>
  <div>
    <router-link replace :to="{ name: 'map' }" class="backBtn">
      返回
    </router-link>
    <img src="@/assets/long2.webp" class="justShowImge" />
  </div>
</template>

<style lang="less" scoped>
.justShowImge {
  display: block;
  width: 100%;
}
</style>
