
import { Options, Vue } from "vue-class-component";
declare const wx: any;
import Ajax from "@/api/Ajax";
@Options({
  mounted() {
    this.$router.replace({
      name: "Start",
    });
  },
  // 微信js初始化
  async wxjsInit() {
    const reData = await Ajax.request.post("/api/Pub/js", {
      url: window.location.href,
    });
    console.log("微信分享配置", reData.data.data.js);
    wx.error((res: any) => {
      alert(JSON.stringify(res));
    });
    wx.config(reData.data.data.js);
    wx.ready(() => {
      wx.updateTimelineShareData({
        title: "动感地带·2022校园新地盘 激活你的燃系元动力", // 分享标题
        link: "https://ynyd2022.link-dev.com/start.html", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: "https://ynyd2022api.link-dev.com/share.jpg", // 分享图标
        success: function () {
          // 设置成功
          console.log("分享设置成功");
        },
      });
      wx.updateAppMessageShareData({
        title: "动感地带·2022校园新地盘 激活你的燃系元动力", // 分享标题
        desc: "大学生活必备“单品”比你想象中的更高能", // 分享描述
        link: "https://ynyd2022.link-dev.com/start.html", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: "https://ynyd2022api.link-dev.com/share.jpg", // 分享图标
        success: function () {
          console.log("分享设置成功");
        },
      });
    });
  },
})
export default class Share extends Vue {}
