
import { Options, Vue } from "vue-class-component";
import { fabric } from "fabric";

@Options({
  emits: ["select"],
  props: {
    type: {
      type: String,
      default: "汉服",
    },
  },
  data() {
    return {
      touch: false, // 是否触摸
      x: 0, // 鼠标
      thisIndex: 0,
      iamges1: [
        require("@/anm/汉服豹自转/hanfu-zhuan-0000.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0001.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0002.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0003.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0004.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0005.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0006.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0007.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0008.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0009.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0010.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0011.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0012.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0013.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0014.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0015.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0016.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0017.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0018.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0019.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0020.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0021.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0022.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0023.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0024.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0025.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0026.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0027.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0028.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0029.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0030.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0031.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0032.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0033.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0034.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0035.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0036.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0037.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0038.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0039.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0040.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0041.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0042.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0043.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0044.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0045.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0046.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0047.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0048.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0049.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0050.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0051.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0052.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0053.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0054.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0055.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0056.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0057.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0058.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0059.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0060.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0061.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0062.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0063.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0064.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0065.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0066.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0067.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0068.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0069.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0070.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0071.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0072.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0073.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0074.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0075.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0076.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0077.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0078.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0079.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0080.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0081.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0082.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0083.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0084.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0085.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0086.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0087.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0088.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0089.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0090.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0091.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0092.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0093.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0094.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0095.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0096.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0097.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0098.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0099.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0100.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0101.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0102.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0103.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0104.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0105.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0106.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0107.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0108.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0109.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0110.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0111.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0112.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0113.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0114.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0115.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0116.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0117.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0118.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0119.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0120.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0121.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0122.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0123.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0124.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0125.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0126.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0127.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0128.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0129.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0130.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0131.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0132.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0133.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0134.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0135.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0136.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0137.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0138.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0139.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0140.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0141.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0142.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0143.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0144.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0145.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0146.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0147.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0148.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0149.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0150.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0151.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0152.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0153.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0154.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0155.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0156.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0157.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0158.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0159.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0160.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0161.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0162.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0163.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0164.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0165.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0166.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0167.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0168.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0169.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0170.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0171.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0172.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0173.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0174.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0175.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0176.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0177.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0178.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0179.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0180.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0181.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0182.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0183.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0184.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0185.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0186.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0187.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0188.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0189.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0190.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0191.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0192.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0193.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0194.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0195.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0196.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0197.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0198.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0199.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0200.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0201.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0202.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0203.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0204.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0205.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0206.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0207.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0208.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0209.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0210.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0211.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0212.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0213.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0214.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0215.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0216.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0217.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0218.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0219.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0220.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0221.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0222.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0223.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0224.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0225.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0226.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0227.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0228.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0229.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0230.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0231.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0232.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0233.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0234.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0235.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0236.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0237.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0238.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0239.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0240.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0241.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0242.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0243.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0244.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0245.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0246.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0247.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0248.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0249.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0250.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0251.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0252.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0253.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0254.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0255.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0256.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0257.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0258.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0259.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0260.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0261.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0262.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0263.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0264.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0265.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0266.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0267.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0268.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0269.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0270.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0271.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0272.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0273.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0274.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0275.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0276.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0277.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0278.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0279.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0280.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0281.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0282.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0283.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0284.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0285.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0286.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0287.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0288.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0289.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0290.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0291.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0292.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0293.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0294.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0295.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0296.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0297.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0298.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0299.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0300.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0301.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0302.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0303.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0304.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0305.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0306.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0307.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0308.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0309.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0310.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0311.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0312.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0313.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0314.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0315.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0316.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0317.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0318.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0319.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0320.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0321.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0322.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0323.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0324.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0325.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0326.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0327.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0328.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0329.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0330.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0331.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0332.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0333.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0334.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0335.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0336.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0337.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0338.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0339.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0340.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0341.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0342.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0343.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0344.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0345.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0346.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0347.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0348.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0349.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0350.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0351.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0352.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0353.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0354.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0355.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0356.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0357.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0358.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0359.webp"),
        require("@/anm/汉服豹自转/hanfu-zhuan-0360.webp"),
      ],
      iamges2: [
        require("@/anm/篮球豹自转/qiufu-zhuan-0000.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0001.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0002.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0003.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0004.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0005.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0006.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0007.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0008.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0009.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0010.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0011.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0012.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0013.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0014.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0015.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0016.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0017.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0018.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0019.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0020.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0021.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0022.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0023.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0024.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0025.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0026.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0027.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0028.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0029.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0030.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0031.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0032.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0033.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0034.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0035.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0036.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0037.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0038.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0039.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0040.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0041.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0042.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0043.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0044.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0045.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0046.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0047.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0048.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0049.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0050.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0051.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0052.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0053.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0054.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0055.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0056.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0057.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0058.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0059.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0060.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0061.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0062.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0063.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0064.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0065.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0066.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0067.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0068.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0069.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0070.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0071.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0072.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0073.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0074.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0075.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0076.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0077.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0078.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0079.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0080.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0081.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0082.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0083.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0084.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0085.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0086.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0087.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0088.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0089.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0090.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0091.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0092.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0093.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0094.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0095.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0096.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0097.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0098.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0099.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0100.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0101.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0102.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0103.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0104.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0105.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0106.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0107.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0108.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0109.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0110.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0111.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0112.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0113.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0114.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0115.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0116.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0117.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0118.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0119.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0120.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0121.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0122.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0123.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0124.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0125.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0126.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0127.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0128.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0129.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0130.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0131.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0132.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0133.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0134.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0135.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0136.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0137.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0138.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0139.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0140.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0141.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0142.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0143.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0144.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0145.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0146.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0147.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0148.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0149.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0150.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0151.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0152.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0153.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0154.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0155.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0156.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0157.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0158.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0159.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0160.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0161.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0162.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0163.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0164.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0165.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0166.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0167.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0168.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0169.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0170.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0171.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0172.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0173.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0174.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0175.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0176.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0177.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0178.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0179.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0180.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0181.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0182.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0183.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0184.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0185.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0186.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0187.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0188.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0189.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0190.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0191.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0192.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0193.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0194.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0195.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0196.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0197.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0198.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0199.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0200.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0201.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0202.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0203.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0204.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0205.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0206.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0207.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0208.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0209.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0210.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0211.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0212.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0213.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0214.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0215.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0216.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0217.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0218.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0219.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0220.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0221.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0222.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0223.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0224.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0225.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0226.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0227.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0228.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0229.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0230.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0231.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0232.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0233.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0234.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0235.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0236.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0237.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0238.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0239.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0240.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0241.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0242.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0243.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0244.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0245.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0246.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0247.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0248.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0249.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0250.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0251.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0252.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0253.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0254.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0255.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0256.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0257.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0258.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0259.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0260.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0261.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0262.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0263.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0264.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0265.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0266.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0267.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0268.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0269.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0270.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0271.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0272.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0273.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0274.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0275.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0276.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0277.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0278.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0279.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0280.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0281.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0282.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0283.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0284.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0285.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0286.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0287.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0288.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0289.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0290.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0291.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0292.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0293.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0294.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0295.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0296.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0297.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0298.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0299.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0300.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0301.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0302.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0303.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0304.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0305.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0306.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0307.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0308.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0309.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0310.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0311.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0312.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0313.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0314.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0315.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0316.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0317.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0318.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0319.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0320.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0321.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0322.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0323.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0324.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0325.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0326.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0327.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0328.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0329.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0330.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0331.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0332.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0333.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0334.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0335.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0336.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0337.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0338.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0339.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0340.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0341.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0342.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0343.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0344.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0345.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0346.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0347.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0348.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0349.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0350.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0351.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0352.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0353.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0354.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0355.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0356.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0357.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0358.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0359.webp"),
        require("@/anm/篮球豹自转/qiufu-zhuan-0360.webp"),
      ],
    };
  },
  mounted() {
    this.init();
    // 启动动画
    this.th = setInterval(this.start, 40);
  },
  methods: {
    // 触摸开始
    touchstart(e: any) {
      // 停止事件冒泡
      e.stopPropagation();
      this.touch = true;
      this.$emit("select");
    },
    // 触摸结束
    touchend(e: any) {
      // 停止事件冒泡
      e.stopPropagation();
      this.touch = false;
    },
    // 鼠标移动
    touchmove(e: any) {
      let liName = this.type === "汉服" ? "iamges1" : "iamges2";
      let thisLi = this[liName];
      if (this.x > e.touches[0].clientX) {
        // 循环播放
        if (this.thisIndex < thisLi.length - 1) {
          this.thisIndex++;
        } else {
          // 动画索引列表更换
          this.thisIndex = 0;
        }
      } else if (this.x < e.touches[0].clientX) {
        // 循环播放
        if (this.thisIndex == 0) {
          this.thisIndex = thisLi.length - 1;
        } else {
          // 动画索引列表更换
          this.thisIndex--;
        }
      }
      this.x = e.touches[0].clientX;
    },
    init() {
      let zom = 1;
      this.canvas = new fabric.Canvas(this.$refs.canvas, {
        width: 180,
        height: 180,
        // 不许人工绘制
        isDrawingMode: false,
        // 不许选中
        selection: false,
      });
      // 设置缩放
      this.canvas.setZoom(zom);
    },
    start() {
      let liName = this.type === "篮球" ? "iamges2" : "iamges1";
      let thisLi = this[liName];
      // 加载图片
      if (typeof thisLi[this.thisIndex] === "string") {
        fabric.Image.fromURL(thisLi[this.thisIndex], (oImg: any) => {
          this.canvas.clear();
          this.canvas.setBackgroundImage(oImg);
          thisLi[this.thisIndex] = oImg;
        });
      } else {
        this.canvas.clear();
        this.canvas.setBackgroundImage(thisLi[this.thisIndex]);
      }
      // 触摸下就不计算
      if (this.touch === false) {
        // 循环播放
        if (this.thisIndex < thisLi.length - 1) {
          this.thisIndex++;
        } else {
          // 动画索引列表更换
          this.thisIndex = 0;
        }
      }
    },
  },
})
export default class Zhuan extends Vue {}
