import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-796b5907"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mainBody" }
const _hoisted_2 = { class: "address animate__animated animate__bounceInDown" }
const _hoisted_3 = {
  key: 0,
  class: "listController"
}
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (
        _ctx.$router.replace({
          name: 'map',
        })
      )),
      class: "backBtn"
    }, " 返回 "),
    _createElementVNode("div", _hoisted_2, " 云南-" + _toDisplayString(_ctx.ares), 1),
    (_ctx.li.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.li, (val, ind) => {
            return (_openBlock(), _createElementBlock("div", {
              onClick: ($event: any) => (_ctx.seletc(val)),
              key: ind,
              class: "schoolName animate__animated animate__fadeInUp"
            }, _toDisplayString(val.name), 9, _hoisted_4))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}