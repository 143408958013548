
import { Options, Vue } from "vue-class-component";
import Zhuan from "@/components/canvas/Zhuan.vue";
import vuex from "vuex";
import Ajax from "@/api/Ajax";

@Options({
  components: {
    Zhuan,
  },
  computed: {
    ...vuex.mapState(["userinfo"]),
  },
  data() {
    return {
      nickname: "",
      start: 0,
      end: 0,
      selectType: null,
    };
  },
  async mounted() {
    if (this.$route.query.token) {
      await this.tokenLogin(this.$route.query.token);
    }
  },
  methods: {
    // 用户信息登录
    async tokenLogin(token: string) {
      const reData = await Ajax.request.post("/api/User/index", {
        token,
      });
      if (reData.data.code === 1) {
        this.$store.commit("setUserInfo", reData.data.data.userinfo);
      }
    },
    // 确定形象
    sub() {
      // 判断nickname是不是空的
      if (this.nickname === "" && this.selectType === "汉服") {
        this.nickname = "汉服豹";
      } else if (this.nickname === "" && this.selectType === "篮球") {
        this.nickname = "篮球豹";
      }
      this.$router.replace({
        name: "map",
        query: {
          nickname: this.nickname,
          type: this.selectType,
        },
      });
    },
    touchstart(e: any) {
      this.start = e.touches[0].clientX;
    },
    touchend(e: any) {
      this.end = e.changedTouches[0].clientX;
      if (this.end - this.start > 50) {
        this.selectChange("汉服");
      } else if (this.end - this.start < -50) {
        this.selectChange("篮球");
      }
    },
    selectChange(type: string) {
      this.selectType = type;
      // 修改nickname
      if (type === "汉服") {
        this.nickname = "汉服豹";
      } else if (type === "篮球") {
        this.nickname = "篮球豹";
      }
    },
  },
})
export default class Select extends Vue {}
