export default class ImageList {
  public static li = [
    // require("@/anm/篮球豹-上/qiufu-shang-0000.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0001.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0002.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0003.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0004.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0005.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0006.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0007.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0008.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0009.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0010.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0011.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0012.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0013.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0014.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0015.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0016.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0017.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0018.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0019.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0020.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0021.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0022.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0023.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0024.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0025.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0026.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0027.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0028.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0029.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0030.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0031.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0032.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0033.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0034.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0035.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0036.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0037.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0038.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0039.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0040.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0041.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0042.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0043.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0044.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0045.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0046.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0047.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0048.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0049.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0050.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0051.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0052.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0053.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0054.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0055.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0056.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0057.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0058.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0059.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0060.webp"),
    // //
    // require("@/anm/篮球豹-下/qiufu-xia-0000.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0001.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0002.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0003.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0004.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0005.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0006.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0007.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0008.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0009.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0010.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0011.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0012.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0013.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0014.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0015.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0016.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0017.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0018.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0019.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0020.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0021.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0022.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0023.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0024.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0025.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0026.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0027.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0028.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0029.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0030.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0031.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0032.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0033.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0034.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0035.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0036.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0037.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0038.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0039.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0040.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0041.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0042.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0043.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0044.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0045.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0046.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0047.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0048.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0049.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0050.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0051.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0052.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0053.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0054.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0055.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0056.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0057.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0058.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0059.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0060.webp"),
    // //
    // require("@/anm/篮球豹-左/qiufu-zuo-0000.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0001.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0002.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0003.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0004.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0005.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0006.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0007.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0008.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0009.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0010.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0011.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0012.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0013.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0014.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0015.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0016.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0017.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0018.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0019.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0020.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0021.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0022.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0023.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0024.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0025.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0026.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0027.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0028.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0029.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0030.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0031.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0032.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0033.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0034.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0035.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0036.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0037.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0038.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0039.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0040.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0041.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0042.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0043.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0044.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0045.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0046.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0047.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0048.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0049.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0050.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0051.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0052.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0053.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0054.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0055.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0056.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0057.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0058.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0059.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0060.webp"),
    // //
    // require("@/anm/篮球豹-右/qiufu-you-0000.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0001.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0002.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0003.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0004.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0005.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0006.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0007.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0008.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0009.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0010.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0011.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0012.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0013.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0014.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0015.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0016.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0017.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0018.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0019.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0020.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0021.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0022.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0023.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0024.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0025.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0026.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0027.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0028.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0029.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0030.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0031.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0032.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0033.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0034.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0035.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0036.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0037.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0038.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0039.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0040.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0041.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0042.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0043.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0044.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0045.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0046.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0047.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0048.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0049.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0050.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0051.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0052.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0053.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0054.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0055.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0056.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0057.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0058.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0059.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0060.webp"),
    // //
    // require("@/anm/篮球豹-上/qiufu-shang-0067.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0068.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0069.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0070.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0067.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0068.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0069.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0070.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0067.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0068.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0069.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0070.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0067.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0068.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0069.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0070.webp"),
    // //
    // require("@/anm/篮球豹-上/qiufu-shang-0070.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0071.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0072.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0073.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0074.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0075.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0076.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0077.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0078.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0079.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0080.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0081.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0082.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0083.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0084.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0085.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0086.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0087.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0088.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0089.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0090.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0091.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0092.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0093.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0094.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0095.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0096.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0097.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0098.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0099.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0100.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0101.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0102.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0103.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0104.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0105.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0106.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0107.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0108.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0109.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0110.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0070.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0071.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0072.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0073.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0074.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0075.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0076.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0077.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0078.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0079.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0080.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0081.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0082.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0083.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0084.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0085.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0086.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0087.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0088.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0089.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0090.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0091.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0092.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0093.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0094.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0095.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0096.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0097.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0098.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0099.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0100.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0101.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0102.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0103.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0104.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0105.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0106.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0107.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0108.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0109.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0110.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0070.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0071.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0072.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0073.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0074.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0075.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0076.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0077.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0078.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0079.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0080.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0081.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0082.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0083.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0084.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0085.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0086.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0087.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0088.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0089.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0090.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0091.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0092.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0093.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0094.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0095.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0096.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0097.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0098.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0099.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0100.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0101.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0102.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0103.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0104.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0105.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0106.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0107.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0108.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0109.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0110.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0070.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0071.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0072.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0073.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0074.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0075.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0076.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0077.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0078.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0079.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0080.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0081.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0082.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0083.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0084.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0085.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0086.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0087.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0088.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0089.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0090.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0091.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0092.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0093.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0094.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0095.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0096.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0097.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0098.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0099.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0100.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0101.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0102.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0103.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0104.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0105.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0106.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0107.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0108.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0109.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0110.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0111.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0112.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0113.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0114.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0115.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0116.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0111.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0112.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0113.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0114.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0115.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0116.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0111.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0112.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0113.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0114.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0115.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0116.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0111.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0112.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0113.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0114.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0115.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0116.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0140.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0141.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0142.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0143.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0144.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0145.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0146.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0147.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0148.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0149.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0150.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0140.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0141.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0142.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0143.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0144.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0145.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0146.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0147.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0148.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0149.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0150.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0140.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0141.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0142.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0143.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0144.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0145.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0146.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0147.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0148.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0149.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0150.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0140.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0141.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0142.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0143.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0144.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0145.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0146.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0147.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0148.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0149.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0150.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0120.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0121.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0122.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0123.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0124.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0125.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0126.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0127.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0128.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0129.webp"),
    // require("@/anm/篮球豹-上/qiufu-shang-0130.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0120.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0121.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0122.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0123.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0124.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0125.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0126.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0127.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0128.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0129.webp"),
    // require("@/anm/篮球豹-下/qiufu-xia-0130.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0120.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0121.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0122.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0123.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0124.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0125.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0126.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0127.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0128.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0129.webp"),
    // require("@/anm/篮球豹-左/qiufu-zuo-0130.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0120.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0121.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0122.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0123.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0124.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0125.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0126.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0127.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0128.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0129.webp"),
    // require("@/anm/篮球豹-右/qiufu-you-0130.webp"),
    // //
    // require("@/anm/汉服豹-上/hanfu-shang-0000.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0001.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0002.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0003.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0004.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0005.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0006.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0007.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0008.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0009.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0010.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0011.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0012.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0013.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0014.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0015.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0016.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0017.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0018.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0019.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0020.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0021.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0022.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0023.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0024.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0025.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0026.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0027.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0028.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0029.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0030.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0031.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0032.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0033.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0034.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0035.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0036.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0037.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0038.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0039.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0040.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0041.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0042.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0043.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0044.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0045.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0046.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0047.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0048.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0049.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0050.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0051.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0052.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0053.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0054.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0055.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0056.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0057.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0058.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0059.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0060.webp"),
    // //
    // require("@/anm/汉服豹-下/hanfu-xia-0000.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0001.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0002.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0003.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0004.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0005.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0006.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0007.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0008.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0009.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0010.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0011.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0012.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0013.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0014.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0015.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0016.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0017.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0018.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0019.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0020.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0021.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0022.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0023.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0024.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0025.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0026.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0027.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0028.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0029.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0030.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0031.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0032.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0033.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0034.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0035.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0036.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0037.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0038.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0039.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0040.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0041.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0042.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0043.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0044.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0045.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0046.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0047.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0048.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0049.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0050.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0051.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0052.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0053.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0054.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0055.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0056.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0057.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0058.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0059.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0060.webp"),
    // //
    // require("@/anm/汉服豹-左/hanfu-zuo-0000.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0001.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0002.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0003.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0004.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0005.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0006.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0007.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0008.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0009.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0010.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0011.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0012.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0013.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0014.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0015.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0016.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0017.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0018.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0019.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0020.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0021.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0022.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0023.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0024.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0025.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0026.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0027.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0028.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0029.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0030.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0031.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0032.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0033.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0034.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0035.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0036.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0037.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0038.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0039.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0040.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0041.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0042.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0043.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0044.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0045.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0046.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0047.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0048.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0049.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0050.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0051.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0052.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0053.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0054.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0055.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0056.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0057.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0058.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0059.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0060.webp"),
    // //
    // require("@/anm/汉服豹-右/hanfu-you-0000.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0001.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0002.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0003.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0004.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0005.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0006.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0007.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0008.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0009.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0010.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0011.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0012.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0013.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0014.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0015.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0016.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0017.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0018.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0019.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0020.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0021.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0022.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0023.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0024.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0025.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0026.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0027.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0028.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0029.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0030.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0031.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0032.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0033.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0034.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0035.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0036.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0037.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0038.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0039.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0040.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0041.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0042.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0043.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0044.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0045.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0046.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0047.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0048.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0049.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0050.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0051.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0052.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0053.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0054.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0055.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0056.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0057.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0058.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0059.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0060.webp"),
    // //
    // require("@/anm/汉服豹-上/hanfu-shang-0067.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0068.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0069.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0070.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0067.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0068.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0069.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0070.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0067.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0068.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0069.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0070.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0067.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0068.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0069.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0070.webp"),
    // //
    // require("@/anm/汉服豹-上/hanfu-shang-0070.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0071.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0072.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0073.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0074.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0075.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0076.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0077.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0078.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0079.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0080.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0081.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0082.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0083.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0084.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0085.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0086.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0087.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0088.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0089.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0090.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0091.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0092.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0093.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0094.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0095.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0096.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0097.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0098.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0099.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0100.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0101.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0102.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0103.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0104.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0105.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0106.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0107.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0108.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0109.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0110.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0070.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0071.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0072.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0073.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0074.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0075.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0076.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0077.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0078.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0079.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0080.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0081.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0082.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0083.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0084.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0085.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0086.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0087.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0088.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0089.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0090.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0091.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0092.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0093.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0094.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0095.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0096.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0097.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0098.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0099.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0100.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0101.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0102.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0103.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0104.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0105.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0106.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0107.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0108.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0109.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0110.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0070.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0071.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0072.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0073.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0074.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0075.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0076.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0077.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0078.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0079.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0080.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0081.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0082.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0083.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0084.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0085.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0086.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0087.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0088.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0089.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0090.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0091.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0092.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0093.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0094.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0095.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0096.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0097.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0098.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0099.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0100.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0101.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0102.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0103.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0104.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0105.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0106.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0107.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0108.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0109.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0110.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0070.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0071.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0072.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0073.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0074.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0075.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0076.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0077.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0078.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0079.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0080.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0081.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0082.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0083.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0084.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0085.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0086.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0087.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0088.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0089.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0090.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0091.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0092.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0093.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0094.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0095.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0096.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0097.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0098.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0099.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0100.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0101.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0102.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0103.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0104.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0105.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0106.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0107.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0108.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0109.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0110.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0111.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0112.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0113.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0114.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0115.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0116.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0111.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0112.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0113.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0114.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0115.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0116.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0111.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0112.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0113.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0114.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0115.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0116.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0111.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0112.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0113.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0114.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0115.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0116.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0140.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0141.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0142.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0143.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0144.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0145.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0146.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0147.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0148.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0149.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0150.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0140.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0141.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0142.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0143.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0144.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0145.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0146.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0147.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0148.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0149.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0150.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0140.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0141.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0142.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0143.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0144.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0145.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0146.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0147.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0148.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0149.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0150.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0140.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0141.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0142.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0143.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0144.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0145.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0146.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0147.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0148.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0149.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0150.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0120.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0121.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0122.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0123.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0124.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0125.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0126.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0127.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0128.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0129.webp"),
    // require("@/anm/汉服豹-上/hanfu-shang-0130.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0120.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0121.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0122.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0123.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0124.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0125.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0126.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0127.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0128.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0129.webp"),
    // require("@/anm/汉服豹-下/hanfu-xia-0130.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0120.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0121.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0122.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0123.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0124.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0125.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0126.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0127.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0128.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0129.webp"),
    // require("@/anm/汉服豹-左/hanfu-zuo-0130.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0120.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0121.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0122.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0123.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0124.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0125.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0126.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0127.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0128.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0129.webp"),
    // require("@/anm/汉服豹-右/hanfu-you-0130.webp"),
    // // 自转素材
    require("@/anm/汉服豹自转/hanfu-zhuan-0000.webp"),
    require("@/anm/汉服豹自转/hanfu-zhuan-0001.webp"),
    require("@/anm/汉服豹自转/hanfu-zhuan-0002.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0003.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0004.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0005.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0006.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0007.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0008.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0009.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0010.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0011.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0012.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0013.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0014.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0015.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0016.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0017.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0018.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0019.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0020.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0021.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0022.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0023.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0024.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0025.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0026.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0027.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0028.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0029.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0030.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0031.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0032.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0033.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0034.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0035.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0036.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0037.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0038.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0039.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0040.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0041.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0042.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0043.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0044.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0045.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0046.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0047.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0048.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0049.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0050.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0051.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0052.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0053.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0054.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0055.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0056.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0057.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0058.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0059.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0060.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0061.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0062.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0063.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0064.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0065.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0066.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0067.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0068.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0069.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0070.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0071.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0072.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0073.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0074.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0075.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0076.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0077.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0078.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0079.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0080.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0081.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0082.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0083.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0084.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0085.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0086.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0087.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0088.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0089.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0090.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0091.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0092.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0093.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0094.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0095.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0096.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0097.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0098.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0099.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0100.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0101.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0102.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0103.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0104.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0105.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0106.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0107.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0108.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0109.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0110.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0111.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0112.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0113.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0114.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0115.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0116.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0117.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0118.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0119.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0120.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0121.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0122.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0123.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0124.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0125.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0126.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0127.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0128.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0129.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0130.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0131.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0132.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0133.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0134.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0135.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0136.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0137.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0138.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0139.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0140.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0141.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0142.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0143.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0144.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0145.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0146.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0147.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0148.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0149.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0150.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0151.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0152.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0153.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0154.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0155.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0156.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0157.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0158.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0159.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0160.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0161.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0162.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0163.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0164.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0165.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0166.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0167.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0168.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0169.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0170.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0171.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0172.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0173.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0174.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0175.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0176.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0177.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0178.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0179.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0180.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0181.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0182.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0183.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0184.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0185.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0186.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0187.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0188.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0189.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0190.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0191.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0192.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0193.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0194.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0195.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0196.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0197.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0198.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0199.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0200.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0201.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0202.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0203.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0204.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0205.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0206.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0207.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0208.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0209.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0210.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0211.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0212.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0213.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0214.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0215.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0216.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0217.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0218.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0219.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0220.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0221.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0222.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0223.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0224.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0225.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0226.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0227.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0228.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0229.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0230.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0231.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0232.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0233.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0234.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0235.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0236.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0237.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0238.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0239.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0240.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0241.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0242.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0243.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0244.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0245.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0246.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0247.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0248.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0249.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0250.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0251.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0252.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0253.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0254.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0255.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0256.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0257.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0258.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0259.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0260.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0261.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0262.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0263.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0264.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0265.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0266.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0267.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0268.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0269.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0270.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0271.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0272.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0273.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0274.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0275.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0276.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0277.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0278.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0279.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0280.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0281.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0282.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0283.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0284.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0285.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0286.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0287.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0288.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0289.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0290.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0291.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0292.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0293.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0294.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0295.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0296.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0297.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0298.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0299.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0300.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0301.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0302.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0303.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0304.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0305.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0306.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0307.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0308.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0309.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0310.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0311.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0312.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0313.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0314.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0315.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0316.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0317.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0318.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0319.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0320.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0321.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0322.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0323.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0324.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0325.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0326.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0327.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0328.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0329.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0330.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0331.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0332.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0333.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0334.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0335.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0336.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0337.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0338.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0339.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0340.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0341.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0342.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0343.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0344.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0345.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0346.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0347.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0348.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0349.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0350.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0351.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0352.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0353.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0354.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0355.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0356.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0357.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0358.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0359.webp"),
    // require("@/anm/汉服豹自转/hanfu-zhuan-0360.webp"),
    // // 球服自转
    require("@/anm/篮球豹自转/qiufu-zhuan-0000.webp"),
    require("@/anm/篮球豹自转/qiufu-zhuan-0001.webp"),
    require("@/anm/篮球豹自转/qiufu-zhuan-0002.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0003.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0004.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0005.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0006.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0007.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0008.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0009.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0010.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0011.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0012.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0013.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0014.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0015.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0016.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0017.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0018.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0019.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0020.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0021.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0022.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0023.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0024.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0025.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0026.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0027.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0028.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0029.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0030.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0031.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0032.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0033.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0034.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0035.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0036.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0037.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0038.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0039.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0040.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0041.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0042.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0043.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0044.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0045.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0046.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0047.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0048.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0049.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0050.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0051.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0052.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0053.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0054.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0055.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0056.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0057.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0058.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0059.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0060.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0061.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0062.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0063.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0064.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0065.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0066.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0067.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0068.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0069.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0070.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0071.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0072.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0073.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0074.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0075.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0076.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0077.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0078.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0079.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0080.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0081.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0082.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0083.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0084.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0085.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0086.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0087.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0088.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0089.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0090.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0091.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0092.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0093.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0094.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0095.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0096.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0097.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0098.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0099.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0100.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0101.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0102.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0103.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0104.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0105.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0106.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0107.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0108.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0109.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0110.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0111.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0112.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0113.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0114.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0115.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0116.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0117.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0118.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0119.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0120.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0121.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0122.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0123.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0124.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0125.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0126.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0127.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0128.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0129.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0130.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0131.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0132.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0133.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0134.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0135.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0136.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0137.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0138.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0139.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0140.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0141.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0142.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0143.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0144.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0145.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0146.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0147.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0148.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0149.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0150.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0151.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0152.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0153.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0154.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0155.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0156.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0157.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0158.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0159.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0160.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0161.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0162.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0163.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0164.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0165.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0166.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0167.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0168.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0169.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0170.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0171.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0172.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0173.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0174.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0175.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0176.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0177.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0178.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0179.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0180.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0181.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0182.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0183.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0184.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0185.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0186.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0187.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0188.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0189.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0190.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0191.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0192.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0193.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0194.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0195.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0196.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0197.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0198.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0199.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0200.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0201.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0202.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0203.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0204.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0205.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0206.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0207.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0208.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0209.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0210.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0211.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0212.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0213.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0214.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0215.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0216.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0217.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0218.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0219.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0220.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0221.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0222.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0223.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0224.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0225.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0226.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0227.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0228.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0229.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0230.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0231.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0232.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0233.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0234.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0235.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0236.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0237.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0238.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0239.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0240.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0241.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0242.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0243.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0244.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0245.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0246.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0247.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0248.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0249.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0250.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0251.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0252.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0253.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0254.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0255.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0256.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0257.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0258.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0259.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0260.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0261.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0262.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0263.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0264.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0265.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0266.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0267.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0268.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0269.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0270.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0271.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0272.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0273.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0274.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0275.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0276.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0277.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0278.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0279.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0280.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0281.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0282.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0283.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0284.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0285.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0286.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0287.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0288.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0289.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0290.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0291.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0292.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0293.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0294.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0295.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0296.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0297.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0298.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0299.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0300.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0301.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0302.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0303.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0304.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0305.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0306.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0307.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0308.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0309.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0310.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0311.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0312.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0313.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0314.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0315.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0316.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0317.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0318.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0319.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0320.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0321.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0322.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0323.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0324.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0325.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0326.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0327.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0328.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0329.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0330.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0331.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0332.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0333.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0334.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0335.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0336.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0337.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0338.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0339.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0340.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0341.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0342.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0343.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0344.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0345.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0346.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0347.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0348.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0349.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0350.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0351.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0352.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0353.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0354.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0355.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0356.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0357.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0358.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0359.webp"),
    // require("@/anm/篮球豹自转/qiufu-zhuan-0360.webp"),
    // 下面是其他资源预加载
    // require("@/assets/SchoolList/1.webp"),
    // require("@/assets/Taocan/1.webp"),
    // require("@/assets/SchoolList/2.webp"),
    // require("@/assets/Taocan/2.webp"),
    // require("@/assets/Taocan/3.webp"),
    // require("@/assets/Taocan/4.webp"),
    // require("@/assets/City/baoshan.webp"),
    // require("@/assets/SchoolList/bg.webp"),
    // require("@/assets/SelectSchool/bg.webp"),
    require("@/assets/Start/bg.webp"),
    // require("@/assets/Taocan/bg.webp"),
    // require("@/assets/Gonglue/bg1.webp"),
    // require("@/assets/Gonglue/bg2.webp"),
    // require("@/assets/Gonglue/bg3.webp"),
    // require("@/assets/City/bg@3x.webp"),
    // require("@/assets/Jihe/bj.webp"),
    // require("@/assets/Yanjiu/bj.webp"),
    // require("@/assets/Loding/bj@3x.webp"),
    // require("@/assets/MapMini/bj@3x.webp"),
    // require("@/assets/Select/bj@3x.webp"),
    // require("@/assets/SelectSchool/btn.webp"),
    require("@/assets/Start/btn.webp"),
    // require("@/assets/Yanjiu/canyu.webp"),
    // require("@/assets/City/chuxiong.webp"),
    // require("@/assets/City/dali.webp"),
    require("@/assets/Map/diban@3x.webp"),
    // require("@/assets/Jihe/dux.webp"),
    // require("@/assets/fanhui.webp"),
    // require("@/assets/MapMini/fanhui@3x.webp"),
    require("@/assets/Map/feiji@3x.webp"),
    require("@/assets/Map/fuliqingnianshe@3x.webp"),
    require("@/assets/Map/gongmen2@3x.webp"),
    require("@/assets/Map/gongmen@3x.webp"),
    require("@/assets/Map/gopngmen3@3x.webp"),
    // require("@/assets/City/honghe.webp"),
    // require("@/assets/Loding/img@3x.webp"),
    // require("@/assets/Loding/jaizai@3x.webp"),
    // require("@/assets/Yanjiu/jieshao.webp"),
    require("@/assets/Map/jihedi2@3x.webp"),
    require("@/assets/Map/jihedi3@3x.webp"),
    require("@/assets/Map/jihedi@3x.webp"),
    require("@/assets/Map/kanchayuan2@3x.webp"),
    require("@/assets/Map/kanchayuan@3x.webp"),
    // require("@/assets/SchoolList/km.webp"),
    // require("@/assets/City/kunming.webp"),
    // require("@/assets/MapMini/liaojie@3x.webp"),
    // require("@/assets/City/lijiang.webp"),
    // require("@/assets/City/lingcang.webp"),
    // require("@/assets/logo.webp"),
    require("@/assets/Map/logo@3x.webp"),
    require("@/assets/Map/naichadian2@3x.webp"),
    require("@/assets/Map/naichadian@3x.webp"),
    require("@/assets/Start/people.webp"),
    // require("@/assets/City/qujing.webp"),
    // require("@/assets/MapMini/ruxue@3x.webp"),
    // require("@/assets/MapMini/ruxuexuzhi@3x.webp"),
    // require("@/assets/SelectSchool/school.webp"),
    require("@/assets/Map/shan@3x.webp"),
    require("@/assets/Map/shatan@3x.webp"),
    // require("@/assets/Jihe/title.webp"),
    // require("@/assets/SelectSchool/title.webp"),
    require("@/assets/Start/title.webp"),
    // require("@/assets/Taocan/title.webp"),
    // require("@/assets/Yanjiu/title.webp"),
    // require("@/assets/Gonglue/title1.webp"),
    // require("@/assets/Gonglue/title2.webp"),
    // require("@/assets/Gonglue/title3.webp"),
    // require("@/assets/City/title@3x.webp"),
    require("@/assets/Map/tup@3x.webp"),
    // require("@/assets/Jihe/up.webp"),
    // require("@/assets/MapMini/vr@3x.webp"),
    require("@/assets/Map/xiaozujin@3x.webp"),
    // require("@/assets/Ran/xin@3x.webp"),
    // require("@/assets/City/xishuangbanna.webp"),
    require("@/assets/Map/yanjiuzhongxin2@3x.webp"),
    require("@/assets/Map/yanjiuzhongxin@3x.webp"),
    require("@/assets/Map/yuncai@3x.webp"),
    // require("@/assets/City/yuxi.webp"),
    // require("@/assets/City/zhaotong.webp"),
    // require("@/assets/MapMini/zhoubian@3x.webp"),
    require("@/assets/Map/zhuangshi@3x.webp"),
    // require("@/assets/Ran/顶部@3x.webp"),
    // 弹窗内容加载
    require("@/assets/D/1/1.webp"),
    require("@/assets/D/5/1.webp"),
    require("@/assets/D/6/1.webp"),
    require("@/assets/D/2.webp"),
    require("@/assets/D/1/2.webp"),
    require("@/assets/D/5/2.webp"),
    require("@/assets/D/6/2.webp"),
    require("@/assets/D/3.webp"),
    require("@/assets/D/4.webp"),
    require("@/assets/D/beijing@3x.webp"),
    require("@/assets/D/guanbi@3x.webp"),
  ];
}
