
import { Options, Vue } from "vue-class-component";
import vuex from "vuex";
import Ajax from "@/api/Ajax";
declare const wx: any;
// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src

@Options({
  components: {},
  computed: {
    ...vuex.mapState(["userinfo"]),
  },
  data() {
    return {
      li1: [], //
      li2: [],
      img: null, // 上传的图片url
      id: null, // 当前记录ID
      // 主域名
      mainUrl: Ajax.url,
    };
  },
  async mounted() {
    if (this.$route.query.token) {
      await this.tokenLogin(this.$route.query.token);
    }
    setTimeout(() => {
      const audio: any = document.getElementById("audio");
      if (!audio.paused) {
        audio.pause();
      }
    }, 1000);
    // 加载用户信息
    this.getInfo();
  },
  methods: {
    async wx() {
      // 等待一秒钟
      await new Promise((resolve) => setTimeout(resolve, 1000));
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      const reData = await Ajax.request.post("/api/Pub/js", {
        url: window.location.href,
      });
      console.log("微信分享配置", reData.data.data.js);
      wx.config(reData.data.data.js);
      wx.ready(() => {
        // 获得当前的域名不包含?之后的数据
        let url = window.location.href.split("?")[0];
        // 拼接参数id
        url += "?id=" + that.id;
        wx.updateTimelineShareData({
          title: "动感地带·2022校园新地盘 激活你的燃系元动力", // 分享标题
          desc: "大学生活必备“单品”  比你想象中的更高能", // 分享描述
          link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: that.img, // 分享图标
          success: function () {
            // 设置成功
            console.log("分享设置成功");
          },
        });
        wx.updateAppMessageShareData({
          title: "动感地带·2022校园新地盘 激活你的燃系元动力", // 分享标题
          desc: "大学生活必备“单品”  比你想象中的更高能", // 分享描述
          link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: that.img, // 分享图标
          success: function () {
            // 设置成功
            console.log("分享设置成功");
          },
        });
      });
    },
    // 喜欢还是不喜欢
    async lk(type: string) {
      if (!this.userinfo) {
        // 微信登录
        this.$store.dispatch("wxlogin");
        return false;
      }
      if (type === "1") {
        this.li1.push(new Date().getTime());
        setTimeout(() => {
          // 删除li1的第一个
          this.li1.shift();
        }, 2000);
      } else {
        this.li2.push(new Date().getTime());
        setTimeout(() => {
          // 删除li1的第一个
          this.li2.shift();
        }, 2000);
      }
      const reData = await Ajax.request.post("/api/User/lk", {
        token: this.userinfo.token,
        id: this.id,
        type,
      });
    },
    // 保存记录
    async saveData() {
      const reData = await Ajax.request.post("/api/User/addRecord", {
        token: this.userinfo.token,
        image: this.img,
      });
      this.id = reData.data.data.id;
      this.wx();
      console.log(this.id);
    },
    async getInfo() {
      if (this.$route.query.id) {
        this.id = this.$route.query.id;
        const reData = await Ajax.request.post("/api/User/getRecord", {
          id: this.id,
        });
        this.id = reData.data.data.id;
        this.img = reData.data.data.image;
      } else if (this.userinfo) {
        // 是登录状态
        this.id = this.$route.query.id;
        const reData = await Ajax.request.post("/api/User/getRecordForUser", {
          token: this.userinfo.token,
          id: this.id,
        });
        this.id = reData.data.data.id;
        this.img = reData.data.data.image;
      }
      this.wx();
    },
    // 开始上传
    start() {
      this.$Spin.show();
    },
    // 上传
    async up(response: any) {
      if (response.code === 0) {
        // 错误提示
        this.$Notice.error({
          title: response.msg,
        });
        return false;
      }
      this.img = response.data.fullurl;
      await this.saveData();
      this.$Spin.hide();
      this.$Notice.success({
        title: "上传成功",
      });
    },
    // 用户信息登录
    async tokenLogin(token: string) {
      const reData = await Ajax.request.post("/api/User/index", {
        token,
      });
      if (reData.data.code === 1) {
        this.$store.commit("setUserInfo", reData.data.data.userinfo);
      }
    },
  },
})
export default class Gonglue1 extends Vue {}
