
import { Options, Vue } from "vue-class-component";
// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src

@Options({
  components: {},
  methods: {
    jump(ares: string) {
      this.$router.replace({
        name: "schoolList",
        query: {
          ares: ares,
        },
      });
    },
  },
})
export default class City extends Vue {}
