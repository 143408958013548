
import { Options, Vue } from "vue-class-component";
// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
import ImgPreload from "@/components/ImgPreload.vue";
import ImageList from "@/ImageList";
import Ajax from "@/api/Ajax";
import vuex from "vuex";
declare const wx: any;

@Options({
  components: {
    ImgPreload,
  },
  watch: {
    $route(to, from) {
      // 判断bgm停止就自动再播放
      const audio: any = document.getElementById("audio");
      if (audio.paused) {
        audio.play();
      }
    },
  },
  computed: {
    ...vuex.mapState(["userinfo"]),
  },
  data() {
    return {
      wx: false, // 不是微信
      imgList: ImageList.li, // 需要预加载的图
    };
  },
  async mounted() {
    this.wxjsInit();
  },
  methods: {
    click() {
      console.log("播放音乐");
      // 查询当前页面中是否有video标签
      const video: any = document.getElementsByTagName("video");
      // 如果存在
      if (video.length == 0) {
        const audio: any = document.getElementById("audio");
        audio.play();
      }
    },
    // 用户信息登录
    async tokenLogin(token: string) {
      const reData = await Ajax.request.post("/api/User/index", {
        token,
      });
      if (reData.data.code === 1) {
        this.$store.commit("setUserInfo", reData.data.data.userinfo);
      }
    },
    // 微信js初始化
    async wxjsInit() {
      const reData = await Ajax.request.post("/api/Pub/js", {
        url: window.location.href,
      });
      console.log("微信分享配置", reData.data.data.js);
      wx.config(reData.data.data.js);
      wx.ready(() => {
        wx.updateTimelineShareData({
          title: "动感地带·2022校园新地盘 激活你的燃系元动力", // 分享标题
          desc: "大学生活必备“单品”比你想象中的更高能", // 分享描述
          link: "https://ynyd2022.link-dev.com/start", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: "https://ynyd2022api.link-dev.com/share.jpg", // 分享图标
          success: function () {
            // 设置成功
            console.log("分享设置成功");
          },
        });
        wx.updateAppMessageShareData({
          title: "动感地带·2022校园新地盘 激活你的燃系元动力", // 分享标题
          desc: "大学生活必备“单品”比你想象中的更高能", // 分享描述
          link: "https://ynyd2022.link-dev.com/start", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: "https://ynyd2022api.link-dev.com/share.jpg", // 分享图标
          success: function () {
            console.log("分享设置成功");
          },
        });
      });
    },
    end() {
      this.endstate = true;
      console.log("加载完成");
    },
    touchmove(e: any) {
      // 定义数组
      const arr: Array<string> = [
        "schoolList",
        "ran",
        "dawei",
        "yanjiuLong",
        "taocan",
        "jihe",
        "jihedi",
        "bbs",
      ];
      const name = this.$route.name;
      // 判断name是否在arr中
      if (!arr.includes(name)) {
        e.preventDefault();
      }
    },
  },
})
export default class App extends Vue {}
