const li = [
  {
    school: "昆明理工大学（呈贡）",
    area: "昆明-呈贡校区周边",
    tag1: "第七街区",
    tag1url: "https://www.xiaohongshu.com/explore/632219240000000011016949",
    tag2: "西南铁血真人CS户外营地",
    tag2url: "https://ctrip.city8.com/kunming/leisure/7ui2oc72ho09b358cf",
  },
  {
    school: "云南中医药大学",
    area: "昆明-呈贡校区周边",
    tag1: "新天地",
    tag1url: "https://ditu.so.com/?pid=d806e04811be0ede&src=seo",
    tag2: "七彩云南",
    tag2url:
      "https://baike.baidu.com/item/%E4%B8%83%E5%BD%A9%E4%BA%91%E5%8D%97?fromModule=lemma_search-box",
  },
  {
    school: "云南师范大学（呈贡）",
    area: "昆明-呈贡校区周边",
    tag1: "仕林街",
    tag1url: "https://www.xiaohongshu.com/explore/63d920cb0000000008022370",
    tag2: "七彩云南欢乐世界",
    tag2url:
      "https://baike.baidu.com/item/%E4%B8%83%E5%BD%A9%E4%BA%91%E5%8D%97%C2%B7%E6%AC%A2%E4%B9%90%E4%B8%96%E7%95%8C?fromModule=lemma_search-box",
  },
  {
    school: "云南艺术学院",
    area: "昆明-呈贡校区周边",
    tag1: "七彩云南第壹城",
    tag1url:
      "https://baike.baidu.com/item/%E4%B8%83%E5%BD%A9%E4%BA%91%E5%8D%97%E7%AC%AC%E5%A3%B9%E5%9F%8E?fromModule=lemma_search-box",
    tag2: "花都海洋世界",
    tag2url: "https://gs.ctrip.com/html5/you/sight/kunming29/2484363.html",
  },
  {
    school: "云南艺术学院文华学院",
    area: "昆明-呈贡校区周边",
    tag1: "万达广场",
    tag1url:
      "https://map.baidu.com/poi/%E6%98%86%E6%98%8E%E5%91%88%E8%B4%A1%E4%B8%87%E8%BE%BE%E5%B9%BF%E5%9C%BA/@11447624.495,2842567.96,19z?uid=9ba79be7f97cf7769d95de2f&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
    tag2: "海晏村",
    tag2url:
      "https://baike.baidu.com/item/%E6%B5%B7%E6%99%8F%E6%9D%91/62949?fromModule=lemma_search-box",
  },
  {
    school: "云南国防工业职业技术学院（开放大学）",
    area: "昆明-呈贡校区周边",
    tag1: "吾悦广场",
    tag1url:
      "https://map.baidu.com/poi/%E6%98%86%E6%98%8E%E5%91%88%E8%B4%A1%E5%90%BE%E6%82%A6%E5%B9%BF%E5%9C%BA/@11447437.845,2844621.06,19z?uid=067aefcb62e98799e011037c&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
    tag2: "七彩云南第壹城",
    tag2url:
      "https://baike.baidu.com/item/%E4%B8%83%E5%BD%A9%E4%BA%91%E5%8D%97%E7%AC%AC%E5%A3%B9%E5%9F%8E?fromModule=lemma_search-box",
  },
  {
    school: "交职高新校区",
    area: "昆明-呈贡校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "昆明市体育学校",
    area: "昆明-呈贡校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "云南化工学校",
    area: "昆明-呈贡校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "云南大学（呈贡）",
    area: "昆明-呈贡校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "昆明医科大学（呈贡）",
    area: "昆明-呈贡校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "云南民族大学（呈贡）",
    area: "昆明-呈贡校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "国土资源学院（呈贡）",
    area: "昆明-呈贡校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "云南新兴职业技术学院（呈贡）",
    area: "昆明-呈贡校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "呈贡云南省华夏中专专业学校",
    area: "昆明-呈贡校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "云南交通职业技术学院",
    area: "昆明-呈贡校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "昆明华航技工学校",
    area: "昆明-呈贡校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "云南财经职业学院",
    area: "昆明-五华校区周边",
    tag1: "园西路小吃街",
    tag1url:
      "https://baike.baidu.com/item/%E5%9B%AD%E8%A5%BF%E8%B7%AF/2285247?fr=ge_ala",
    tag2: "建设路",
    tag2url:
      "https://map.baidu.com/search/%E6%98%86%E6%98%8E%E5%BB%BA%E8%AE%BE%E8%B7%AF/@11421659.194028776,2877721.934999999,10.68z?querytype=con&wd=%E6%98%86%E6%98%8E%E5%BB%BA%E8%AE%BE%E8%B7%AF&c=104&provider=pc-aladin&pn=0&device_ratio=2&da_src=shareurl",
  },
  {
    school: "云南经济管理职业学院",
    area: "昆明-五华校区周边",
    tag1: "文林街",
    tag1url:
      "https://baike.baidu.com/item/%E6%96%87%E6%9E%97%E8%A1%97?fromModule=lemma_search-box",
    tag2: "文林街",
    tag2url:
      "https://baike.baidu.com/item/%E6%96%87%E6%9E%97%E8%A1%97?fromModule=lemma_search-box",
  },
  {
    school: "昆明卫生职业学院",
    area: "昆明-五华校区周边",
    tag1: "新迎步行街",
    tag1url: "http://news.sohu.com/a/574232366_682089",
    tag2: "云纺商业城",
    tag2url: "https://you.ctrip.com/sight/kunming29/5170973.html",
  },
  {
    school: "云南旅游职业学院",
    area: "昆明-五华校区周边",
    tag1: "南屏街",
    tag1url:
      "https://baike.baidu.com/item/%E5%8D%97%E5%B1%8F%E8%A1%97?fromModule=lemma_search-box",
    tag2: "南强街",
    tag2url:
      "https://baike.baidu.com/item/%E5%8D%97%E5%BC%BA%E8%A1%97?fromModule=lemma_search-box",
  },
  {
    school: "云南理工大学津桥学院",
    area: "昆明-五华校区周边",
    tag1: "南强街",
    tag1url:
      "https://baike.baidu.com/item/%E5%8D%97%E5%BC%BA%E8%A1%97?fromModule=lemma_search-box",
    tag2: "翠湖公园",
    tag2url:
      "https://baike.baidu.com/item/%E7%BF%A0%E6%B9%96%E5%85%AC%E5%9B%AD%20%20/6515620?fromModule=lemma_search-box",
  },
  {
    school: "云南师范大学文理学院 （五华）",
    area: "昆明-五华校区周边",
    tag1: "麻园社区",
    tag1url:
      "https://baike.baidu.com/item/%E9%BA%BB%E5%9B%AD/24576030?fr=ge_ala",
    tag2: "同德广场",
    tag2url:
      "https://baike.baidu.com/item/%E5%90%8C%E5%BE%B7%E6%98%86%E6%98%8E%E5%B9%BF%E5%9C%BA?fromtitle=%E5%90%8C%E5%BE%B7%E5%B9%BF%E5%9C%BA&fromid=15453344&fromModule=lemma_search-box",
  },
  {
    school: "昆明医学院海源学院(五华)",
    area: "昆明-五华校区周边",
    tag1: "农大美食街",
    tag1url:
      "https://map.baidu.com/search/%E5%86%9C%E5%A4%A7%E7%BE%8E%E9%A3%9F%E8%A1%97/@11428339.27865828,2859922.9050000003,12.1z?querytype=con&wd=%E5%86%9C%E5%A4%A7%E7%BE%8E%E9%A3%9F%E8%A1%97&c=104&provider=pc-aladin&pn=0&device_ratio=2&da_src=shareurl",
    tag2: "海埂公园",
    tag2url:
      "https://baike.baidu.com/item/%E6%B5%B7%E5%9F%82%E5%85%AC%E5%9B%AD?fromModule=lemma_search-box",
  },
  {
    school: "昆明冶金高等专科学校（五华）",
    area: "昆明-五华校区周边",
    tag1: "龙泉路财大步行街",
    tag1url:
      "https://map.baidu.com/search/%E9%BE%99%E6%B3%89%E8%B7%AF%E8%B4%A2%E5%A4%A7%E6%AD%A5%E8%A1%8C%E8%A1%97/@11435207.036043165,2868558.02,17.83z?querytype=con&wd=%E9%BE%99%E6%B3%89%E8%B7%AF%E8%B4%A2%E5%A4%A7%E6%AD%A5%E8%A1%8C%E8%A1%97&c=104&provider=pc-aladin&pn=0&device_ratio=2&da_src=shareurl",
    tag2: "云南民族村",
    tag2url:
      "https://baike.baidu.com/item/%E4%BA%91%E5%8D%97%E6%B0%91%E6%97%8F%E6%9D%91?fromModule=lemma_search-box",
  },
  {
    school: "昆明理工大学莲华校区",
    area: "昆明-五华校区周边",
    tag1: "",
    tag1url: "",
    tag2: "昆明融创文旅城",
    tag2url:
      "https://baike.baidu.com/item/%E6%98%86%E6%98%8E%E8%9E%8D%E5%88%9B%E6%96%87%E6%97%85%E5%9F%8E/23496180?fromModule=search-result_lemma-recommend",
  },
  {
    school: "昆明艺术职业学院",
    area: "昆明-五华校区周边",
    tag1: "",
    tag1url: "",
    tag2: "昆明西山森林公园",
    tag2url:
      "https://baike.baidu.com/item/%E6%98%86%E6%98%8E%E8%A5%BF%E5%B1%B1%E6%A3%AE%E6%9E%97%E5%85%AC%E5%9B%AD/1819766?fromModule=search-result_lemma-recommend",
  },
  {
    school: "云南大学（本部）",
    area: "昆明-五华校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "云南师范大学（本部）",
    area: "昆明-五华校区周边",
    tag1: "",
    tag1url: "",
    tag2: "南亚风情第壹城",
    tag2url:
      "https://baike.baidu.com/item/%E5%8D%97%E4%BA%9A%E9%A3%8E%E6%83%85%E7%AC%AC%E5%A3%B9%E5%9F%8E?fromModule=lemma_search-box",
  },
  {
    school: "师大商学院（昆明城市学院）",
    area: "昆明-五华校区周边",
    tag1: "",
    tag1url: "",
    tag2: "景星街",
    tag2url:
      "https://baike.baidu.com/item/%E6%99%AF%E6%98%9F%E8%A1%97?fromModule=lemma_search-box#2",
  },
  {
    school: "昆明技师学院",
    area: "昆明-五华校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "云南警官学院",
    area: "昆明-五华校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "云南文化艺术职业学院",
    area: "昆明-西山校区周边",
    tag1: "园西路小吃街",
    tag1url:
      "https://baike.baidu.com/item/%E5%9B%AD%E8%A5%BF%E8%B7%AF/2285247?fr=ge_ala",
    tag2: "建设路",
    tag2url:
      "https://map.qq.com/?what=%E5%BB%BA%E8%AE%BE%E8%B7%AF&city=%E6%98%86%E6%98%8E%E5%B8%82&type=poi&init_view=2&ref=TXZBZ-JFC3P-4NPDJ-L2U3C-VHY7K-NWF4U",
  },
  {
    school: "云南体育运动职业技术学院",
    area: "昆明-西山校区周边",
    tag1: "文林街",
    tag1url:
      "https://baike.baidu.com/item/%E6%96%87%E6%9E%97%E8%A1%97?fromModule=lemma_search-box",
    tag2: "文林街",
    tag2url:
      "https://baike.baidu.com/item/%E6%96%87%E6%9E%97%E8%A1%97?fromModule=lemma_search-box",
  },
  {
    school: "云南经贸外事职业学院",
    area: "昆明-西山校区周边",
    tag1: "新迎步行街",
    tag1url: "http://news.sohu.com/a/574232366_682089",
    tag2: "云纺商业城",
    tag2url: "https://you.ctrip.com/sight/kunming29/5170973.html",
  },
  {
    school: "云南省民族中等专业学校",
    area: "昆明-西山校区周边",
    tag1: "南屏街",
    tag1url:
      "https://baike.baidu.com/item/%E5%8D%97%E5%B1%8F%E8%A1%97?fromModule=lemma_search-box",
    tag2: "南强街",
    tag2url:
      "https://baike.baidu.com/item/%E5%8D%97%E5%BC%BA%E8%A1%97?fromModule=lemma_search-box",
  },
  {
    school: "云南省昆明市盲哑学校",
    area: "昆明-西山校区周边",
    tag1: "麻园社区",
    tag1url:
      "https://baike.baidu.com/item/%E9%BA%BB%E5%9B%AD/24576030?fr=ge_ala",
    tag2: "翠湖公园",
    tag2url:
      "https://baike.baidu.com/item/%E7%BF%A0%E6%B9%96%E5%85%AC%E5%9B%AD%20%20/6515620?fromModule=lemma_search-box",
  },
  {
    school: "云南新华电脑职业培训学校",
    area: "昆明-西山校区周边",
    tag1: "云纺商业城",
    tag1url: "https://you.ctrip.com/sight/kunming29/5170973.html",
    tag2: "同德广场",
    tag2url:
      "https://baike.baidu.com/item/%E5%90%8C%E5%BE%B7%E6%98%86%E6%98%8E%E5%B9%BF%E5%9C%BA?fromtitle=%E5%90%8C%E5%BE%B7%E5%B9%BF%E5%9C%BA&fromid=15453344&fromModule=lemma_search-box",
  },
  {
    school: "云南大学滇池学院(海埂校区)",
    area: "昆明-西山校区周边",
    tag1: "南亚风情第壹城",
    tag1url:
      "https://baike.baidu.com/item/%E5%8D%97%E4%BA%9A%E9%A3%8E%E6%83%85%E7%AC%AC%E5%A3%B9%E5%9F%8E?fromModule=lemma_search-box",
    tag2: "海埂公园",
    tag2url:
      "https://baike.baidu.com/item/%E6%B5%B7%E5%9F%82%E5%85%AC%E5%9B%AD?fromModule=lemma_search-box",
  },
  {
    school: "新东方烹饪学院",
    area: "昆明-西山校区周边",
    tag1: "",
    tag1url: "",
    tag2: "云南民族村",
    tag2url:
      "https://baike.baidu.com/item/%E4%BA%91%E5%8D%97%E6%B0%91%E6%97%8F%E6%9D%91?fromModule=lemma_search-box",
  },
  {
    school: "云南工商学院（滇池校区）",
    area: "昆明-西山校区周边",
    tag1: "",
    tag1url: "",
    tag2: "昆明融创文旅城",
    tag2url:
      "https://baike.baidu.com/item/%E6%98%86%E6%98%8E%E8%9E%8D%E5%88%9B%E6%96%87%E6%97%85%E5%9F%8E/23496180?fromModule=search-result_lemma-recommend",
  },
  {
    school: "昆明市西山区职业高级中学",
    area: "昆明-西山校区周边",
    tag1: "",
    tag1url: "",
    tag2: "昆明西山森林公园",
    tag2url:
      "https://baike.baidu.com/item/%E6%98%86%E6%98%8E%E8%A5%BF%E5%B1%B1%E6%A3%AE%E6%9E%97%E5%85%AC%E5%9B%AD/1819766?fromModule=search-result_lemma-recommend",
  },
  {
    school: "云南城市建设职业学院",
    area: "昆明-嵩明校区周边",
    tag1: "兰茂广场",
    tag1url: "https://ditu.so.com/?pid=1fcebeaea3108a9a&src=seo",
    tag2: "星耀水乡",
    tag2url:
      "https://baike.sogou.com/v69617007.htm?fromTitle=%E6%98%9F%E8%80%80%E6%B0%B4%E4%B9%A1&ch=frombaikevr",
  },
  {
    school: "云南师范大学文理学院（嵩明）\n—昆明文理学院",
    area: "昆明-嵩明校区周边",
    tag1: "隆源美食广场",
    tag1url: "https://www.651700.com/article/article_17596.html",
    tag2: "兰茂广场",
    tag2url: "https://ditu.so.com/?pid=1fcebeaea3108a9a&src=seo",
  },
  {
    school: "昆明市卫生学校",
    area: "昆明-嵩明校区周边",
    tag1: "银杏广场",
    tag1url: "https://www.651700.com/article/article_5181.html",
    tag2: "隆源美食广场",
    tag2url: "https://www.651700.com/article/article_17596.html",
  },
  {
    school: "云南经贸外事职业学院",
    area: "昆明-嵩明校区周边",
    tag1: "嵩明尚汇坊",
    tag1url:
      "https://baijiahao.baidu.com/s?id=1750992523793518917&wfr=spider&for=pc",
    tag2: "银杏广场",
    tag2url: "https://www.651700.com/article/article_5181.html",
  },
  {
    school: "云南商务职业学院",
    area: "昆明-嵩明校区周边",
    tag1: "汇尔佳",
    tag1url: "https://ditu.so.com/?pid=42e32130c6b69eb1&src=seo",
    tag2: "嵩明尚汇坊",
    tag2url:
      "https://baijiahao.baidu.com/s?id=1750992523793518917&wfr=spider&for=pc",
  },
  {
    school: "云南工商学院",
    area: "昆明-嵩明校区周边",
    tag1: "领秀知识城",
    tag1url:
      "https://baike.baidu.com/item/%E9%A2%86%E7%A7%80%E7%9F%A5%E8%AF%86%E5%9F%8E?fromModule=lemma_search-box",
    tag2: "汇尔佳",
    tag2url: "https://ditu.so.com/?pid=42e32130c6b69eb1&src=seo",
  },
  {
    school: "云南林业职业技术学院",
    area: "昆明-嵩明校区周边",
    tag1: "火锅城",
    tag1url:
      "https://map.baidu.com/search/%E5%B5%A9%E6%98%8E%E7%81%AB%E9%94%85%E5%9F%8E/@11454566.788381295,2891673.055,12.68z?querytype=con&wd=%E5%B5%A9%E6%98%8E%E7%81%AB%E9%94%85%E5%9F%8E&c=864&provider=pc-aladin&pn=0&device_ratio=2&da_src=shareurl",
    tag2: "领秀知识城",
    tag2url:
      "https://baike.baidu.com/item/%E9%A2%86%E7%A7%80%E7%9F%A5%E8%AF%86%E5%9F%8E?fromModule=lemma_search-box",
  },
  {
    school: "昆明理工大学—城市学院",
    area: "昆明-嵩明校区周边",
    tag1: "",
    tag1url: "",
    tag2: "火锅城",
    tag2url:
      "https://map.baidu.com/search/%E5%B5%A9%E6%98%8E%E7%81%AB%E9%94%85%E5%9F%8E/@11454566.788381295,2891673.055,12.68z?querytype=con&wd=%E5%B5%A9%E6%98%8E%E7%81%AB%E9%94%85%E5%9F%8E&c=864&provider=pc-aladin&pn=0&device_ratio=2&da_src=shareurl",
  },
  {
    school: "昆明医科大学海源学院",
    area: "昆明-嵩明校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "昆明幼儿师范高等专科学校",
    area: "昆明-嵩明校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "云南大学滇池学院(嵩明)",
    area: "昆明-嵩明校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "云南师范大学商学院—昆明城市学院",
    area: "昆明-嵩明校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "昆明学院",
    area: "昆明-官渡校区周边",
    tag1: "官渡古镇入口                              ",
    tag1url:
      "https://map.baidu.com/search/%E5%AE%98%E6%B8%A1%E5%8F%A4%E9%95%87%E5%85%A5%E5%8F%A3/@11439281.883303642,2852741.4676576,17.64z?querytype=con&wd=%E5%AE%98%E6%B8%A1%E5%8F%A4%E9%95%87%E5%85%A5%E5%8F%A3&c=104&provider=pc-aladin&pn=0&device_ratio=2&da_src=shareurl",
    tag2: "云秀康园运动公园",
    tag2url:
      "https://map.baidu.com/poi/%E4%BA%91%E7%A7%80%E5%BA%B7%E5%9B%AD%E8%BF%90%E5%8A%A8%E5%85%AC%E5%9B%AD/@11439116.945,2850463.32,19z?uid=b292a1e66e9132c67ec8766f&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
  },
  {
    school: "昆明理工大学津桥学院",
    area: "昆明-官渡校区周边",
    tag1: "园西路美食街                               ",
    tag1url:
      "https://baike.baidu.com/item/%E5%9B%AD%E8%A5%BF%E8%B7%AF/2285247?fr=ge_ala",
    tag2: "俊发西亮塘湿地公园   ",
    tag2url: "https://you.ctrip.com/sight/kunming29/69297696.html",
  },
  {
    school: "昆明铁道职业技术学院",
    area: "昆明-官渡校区周边",
    tag1: "吃货营地                                      ",
    tag1url:
      "https://mbd.baidu.com/newspage/data/dtlandingsuper?nid=dt_4237836805893266795",
    tag2: " FAITH龙湖滑板公园 ",
    tag2url:
      "https://map.baidu.com/poi/FAITH%E9%BE%99%E6%B9%96%E6%BB%91%E6%9D%BF%E5%85%AC%E5%9B%AD/@11436658.625,2861749.54,19z?uid=1b532ae310ce87b75a3ac8b0&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
  },
  {
    school: "云南省邮电学校\n（昆明铁道职业技术学院洋浦分校）",
    area: "昆明-官渡校区周边",
    tag1: "茶马花街                                  ",
    tag1url:
      "https://baike.baidu.com/item/%E8%8C%B6%E9%A9%AC%E8%8A%B1%E8%A1%97/18158536?fr=ge_ala",
    tag2: "如意楼、龙泉寺",
    tag2url:
      "https://map.baidu.com/search/%E6%98%86%E6%98%8E%E5%A6%82%E6%84%8F%E6%A5%BC/@11430379.163900929,2859921.685,13.76z?querytype=con&wd=%E6%98%86%E6%98%8E%E5%A6%82%E6%84%8F%E6%A5%BC&c=104&provider=pc-aladin&pn=0&device_ratio=2&da_src=shareurl",
  },
  {
    school: "云南司法警官职业学院",
    area: "昆明-官渡校区周边",
    tag1: "老昆明网红小吃城",
    tag1url:
      "https://map.baidu.com/poi/%E8%80%81%E6%98%86%E6%98%8E%E4%B8%87%E5%AE%8F%E5%B0%8F%E5%90%83%E5%9F%8E/@11440086,2861854,13z?uid=bd550ea99d5e977b21026261&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
    tag2: "福保水上蔬菜公园",
    tag2url:
      "https://baike.baidu.com/item/%E9%BE%99%E6%B3%89%E5%AF%BA/13022002?fr=ge_ala",
  },
  {
    school: "昆明市官渡区职业高级中学",
    area: "昆明-官渡校区周边",
    tag1: "",
    tag1url: "",
    tag2: " 水月庵",
    tag2url:
      "https://map.baidu.com/poi/%E7%A6%8F%E4%BF%9D%E6%B0%B4%E4%B8%8A%E4%B9%90%E8%94%AC%E8%8F%9C%E5%85%AC%E5%9B%AD/@11440086,2861854,13z?uid=3afb031d07cf054d7ec87636&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
  },
  {
    school: "云南农业职业技术学院",
    area: "昆明-官渡校区周边",
    tag1: "",
    tag1url: "",
    tag2: "云南煤炭地质博物馆",
    tag2url:
      "https://map.baidu.com/poi/%E4%B8%8A%E6%B5%B7%E5%BA%B5/@11437324.695,2855184.36,19z?uid=21788840418ad3130f0e6b8c&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
  },
  {
    school: "国土资源学院（官渡）",
    area: "昆明-官渡校区周边",
    tag1: "",
    tag1url: "",
    tag2: "昆明褔保村文化城、",
    tag2url:
      "https://map.baidu.com/poi/%E4%BA%91%E5%8D%97%E7%85%A4%E7%82%AD%E5%9C%B0%E8%B4%A8%E5%8D%9A%E7%89%A9%E9%A6%86/@11440086,2861854,13z?uid=c3c1f508a4fc6e1440d63e1a&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
  },
  {
    school: "云南广播电视学校(文化艺术分校）",
    area: "昆明-官渡校区周边",
    tag1: "",
    tag1url: "",
    tag2: "如意公园",
    tag2url:
      "https://baike.baidu.com/item/%E6%98%86%E6%98%8E%E7%A6%8F%E4%BF%9D%E6%96%87%E5%8C%96%E5%9F%8E/6693534?fr=ge_ala",
  },
  {
    school: "云南农业大学",
    area: "昆明-盘龙校区周边",
    tag1: "瑞鼎城",
    tag1url:
      "https://baike.baidu.com/item/%E7%91%9E%E9%BC%8E%E5%9F%8E/18004078?fr=ge_ala",
    tag2: "昙华寺",
    tag2url:
      "https://baike.baidu.com/item/%E6%98%99%E5%8D%8E%E5%AF%BA/3313462?fr=ge_ala",
  },
  {
    school: "西南林业大学",
    area: "昆明-盘龙校区周边",
    tag1: "新迎新城",
    tag1url:
      "https://map.baidu.com/poi/%E6%96%B0%E8%BF%8E%E6%96%B0%E5%9F%8E%E8%B4%AD%E7%89%A9%E4%B8%AD%E5%BF%83/@11437696.975,2864665.75,19z?uid=5a46cd65b5197967f11937ba&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
    tag2: "世界园艺博览园",
    tag2url:
      "https://baike.baidu.com/item/%E6%98%86%E6%98%8E%E4%B8%96%E5%8D%9A%E5%9B%AD/1025706?fromModule=lemma_search-box&fromtitle=%E4%B8%96%E7%95%8C%E5%9B%AD%E8%89%BA%E5%8D%9A%E8%A7%88%E5%9B%AD&fromid=9126389",
  },
  {
    school: "云南科技信息职业学院",
    area: "昆明-盘龙校区周边",
    tag1: "七彩俊园",
    tag1url:
      "https://baike.baidu.com/item/%E4%B8%83%E5%BD%A9%E4%BF%8A%E5%9B%AD/9448112?fr=ge_ala",
    tag2: "昆明金殿名胜区",
    tag2url:
      "https://baike.baidu.com/item/%E6%98%86%E6%98%8E%E9%87%91%E6%AE%BF%E5%90%8D%E8%83%9C%E5%8C%BA/9892029?fromModule=lemma_search-box&fromtitle=%E9%87%91%E6%AE%BF&fromid=18329602",
  },
  {
    school: "云南机电职业技术学院",
    area: "昆明-盘龙校区周边",
    tag1: "同德",
    tag1url:
      "https://baike.baidu.com/item/%E5%90%8C%E5%BE%B7%E6%98%86%E6%98%8E%E5%B9%BF%E5%9C%BA/5044464?fr=ge_ala",
    tag2: "昆明黑龙潭",
    tag2url:
      "https://baike.baidu.com/item/%E6%98%86%E6%98%8E%E9%BB%91%E9%BE%99%E8%B0%AD/6195677?fr=ge_ala",
  },
  {
    school: "盘龙区职业高级中学",
    area: "昆明-盘龙校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "昆明市艺术学校",
    area: "昆明-盘龙校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "云南省贸易经济学校",
    area: "昆明-盘龙校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "云南商务信息工程学校",
    area: "昆明-盘龙校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "中医药中专学校/云南东方中医药中等专业学校",
    area: "昆明-盘龙校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "云南新兴职业学院",
    area: "昆明-盘龙校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "昆明理工城市学院",
    area: "昆明-盘龙校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "云南林业职业技术学院（金殿）",
    area: "昆明-盘龙校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "昆明冶金高等专科学校（安宁）",
    area: "昆明-安宁校区周边",
    tag1: "天源美食城（世贸广场） \n",
    tag1url:
      "https://map.baidu.com/poi/%E5%AE%89%E5%AE%81%E4%B8%96%E8%B4%B8%E5%B9%BF%E5%9C%BA/@11409289.455,2848843.76,19z?uid=6ff3395871e797b4d5b74e6a&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
    tag2: "宁湖公园 \n",
    tag2url:
      "https://map.baidu.com/poi/%E5%AE%81%E6%B9%96%E5%85%AC%E5%9B%AD/@11406303.115,2848054.09,19z?uid=2869e52d0b77e7b46c3e1b96&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
  },
  {
    school: "云南交通技师学院",
    area: "昆明-安宁校区周边",
    tag1: "特殊美食街",
    tag1url:
      "https://map.baidu.com/poi/%E7%89%B9%E6%AE%8A%E7%BE%8E%E9%A3%9F%E8%A1%97/@11407850.495,2846533.8,19z?uid=bc7ffe8d9773309d6c3e1b67&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
    tag2: "玉龙湾景区、",
    tag2url:
      "https://baike.baidu.com/item/%E7%8E%89%E9%BE%99%E6%B9%BE%E6%99%AF%E5%8C%BA/8781937?fr=ge_ala",
  },
  {
    school: "昆明工业职业技术学院",
    area: "昆明-安宁校区周边",
    tag1: "安宁美食街",
    tag1url:
      "https://map.baidu.com/poi/%E6%98%86%E7%95%B9%E8%A5%BF%E8%B7%AF/@11403685.786202831,2849098.9050000003,15.01z?uid=6ce1fb549b0c105879dbd2bc&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
    tag2: "安宁东湖公园、",
    tag2url:
      "https://map.baidu.com/poi/%E4%B8%9C%E6%B9%96%E5%85%AC%E5%9B%AD/@11410138.545,2848374.5,19z?uid=5814d4ede5db5cd388fc06c0&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
  },
  {
    school: "云南财经大学安宁校区",
    area: "昆明-安宁校区周边",
    tag1: "",
    tag1url: "",
    tag2: " 安宁青龙峡风景区",
    tag2url:
      "https://map.baidu.com/?newmap=1&s=inf%26uid%3D9a00002aeca4ae9d16a190e3%26wd%3D%E9%9D%92%E9%BE%99%E5%B3%A1%E9%A3%8E%E6%99%AF%E5%8C%BA%26all%3D1%26c%3D104&from=alamap&tpl=map_singlepoint",
  },
  {
    school: "云南工程职业学院",
    area: "昆明-安宁校区周边",
    tag1: "",
    tag1url: "",
    tag2: "安宁百花公园、",
    tag2url:
      "https://map.baidu.com/poi/%E7%99%BE%E8%8A%B1%E5%85%AC%E5%9B%AD/@11409238.535,2848505.02,19z?uid=ea4a3dc95000a12f4e5547e6&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
  },
  {
    school: "云南技师学院",
    area: "昆明-安宁校区周边",
    tag1: "",
    tag1url: "",
    tag2: "安宁邑尾里生态谷",
    tag2url:
      "https://map.baidu.com/poi/%E9%82%91%E5%B0%BE%E9%87%8C/@11400844.845,2857465.74,19z?uid=d52831f9dee4d5a62efaa7e1&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
  },
  {
    school: "云南经济管理职业学院（安宁）",
    area: "昆明-安宁校区周边",
    tag1: "",
    tag1url: "",
    tag2: "金色螳螂川风景区",
    tag2url:
      "https://map.baidu.com/poi/%E9%87%91%E8%89%B2%E8%9E%B3%E8%9E%82%E5%B7%9D/@11403308.615,2856670.53,19z?uid=9dba619946f1f98d4c776e4e&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
  },
  {
    school: "云南理工职业学院",
    area: "昆明-安宁校区周边",
    tag1: "",
    tag1url: "",
    tag2: "安宁珍珠泉",
    tag2url: "https://www.xiaohongshu.com/explore/60f64f30000000000102421d",
  },
  {
    school: "云南轻纺职业学院",
    area: "昆明-安宁校区周边",
    tag1: "",
    tag1url: "",
    tag2: "温泉摩崖石刻群",
    tag2url:
      "https://baike.baidu.com/item/%E6%B8%A9%E6%B3%89%E6%91%A9%E5%B4%96%E7%9F%B3%E5%88%BB%E7%BE%A4/23805206?fr=ge_ala",
  },
  {
    school: "云南农业职业技术学院云安产业学院",
    area: "昆明-安宁校区周边",
    tag1: "",
    tag1url: "",
    tag2: "晓塘花园",
    tag2url:
      "https://map.baidu.com/poi/%E6%99%93%E5%A1%98%E8%8A%B1%E5%9B%AD/@11410323.935,2846342.04,19z?uid=0489db62c8555d5372210bdd&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
  },
  {
    school: "安宁市职业高级中学",
    area: "昆明-安宁校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "昆明市台湘科技学校",
    area: "昆明-宜良校区周边",
    tag1: "宜良山后樱花谷-美食街",
    tag1url: "http://km.bendibao.com/tour/202331/72483.shtm",
    tag2: "九乡风景区",
    tag2url:
      "https://baike.baidu.com/item/%E5%AE%9C%E8%89%AF%E4%B9%9D%E4%B9%A1%E9%A3%8E%E6%99%AF%E5%8C%BA?fromtitle=%E4%B9%9D%E4%B9%A1%E9%A3%8E%E6%99%AF%E5%8C%BA&fromid=1355395&fromModule=lemma_search-box",
  },
  {
    school: "",
    area: "昆明-宜良校区周边",
    tag1: "",
    tag1url: "",
    tag2: "岩泉风景区",
    tag2url:
      "https://baike.baidu.com/item/%E5%B2%A9%E6%B3%89%E9%A3%8E%E6%99%AF%E5%8C%BA/19258135?fr=ge_ala",
  },
  {
    school: "",
    area: "昆明-宜良校区周边",
    tag1: "",
    tag1url: "",
    tag2: "温泉街",
    tag2url:
      "https://map.baidu.com/poi/%E5%B0%8F%E9%A9%AC%E5%9F%8E%E6%B8%A9%E6%B3%89/@11487159.525,2840305.2,18.87z?uid=0efe825b0ea03bf800294f55&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
  },
  {
    school: "",
    area: "昆明-宜良校区周边",
    tag1: "食尚旺角疯狂烤翅(宜良店)",
    tag1url:
      "https://map.baidu.com/poi/%E7%96%AF%E7%8B%82%E9%B9%85%E7%BF%85/@11482626.975,2848745.45,19z?uid=52cb0ced7f7f895f0d3dda68&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
    tag2: "",
    tag2url: "",
  },
  {
    school: "云南水利水电职业学院",
    area: "昆明-富民校区周边",
    tag1: "北桥美食荟",
    tag1url:
      "https://map.baidu.com/poi/%E7%A6%8F%E6%B0%91%E5%8C%97%E6%A1%A5%E7%BE%8E%E9%A3%9F%E8%8D%9F/@11411844.155,2885159.79,19z?uid=708adb657bd3b27edbeced4b&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
    tag2: "龙窝垂钓中心",
    tag2url: "https://www.diaoyu.com/diaochang/kunming/227787.html",
  },
  {
    school: "玉溪卫生学校",
    area: "玉溪校区周边",
    tag1: "美食人家                                    ",
    tag1url: "http://life.city8090.com/yuxi/s_4299",
    tag2: "映月潭温泉  ",
    tag2url:
      "https://baike.baidu.com/item/%E6%98%A0%E6%9C%88%E6%BD%AD%E6%B8%A9%E6%B3%89/6402863?fr=ge_ala",
  },
  {
    school: "玉溪农业职业技术学院",
    area: "玉溪校区周边",
    tag1: "胡九                                                      ",
    tag1url:
      "https://baike.baidu.com/item/%E8%83%A1%E4%B9%9D%E5%B0%8F%E5%90%83/14244837?fr=ge_ala",
    tag2: "抚仙湖风景区   ",
    tag2url:
      "https://baike.baidu.com/item/%E6%8A%9A%E4%BB%99%E6%B9%96%E9%A3%8E%E6%99%AF%E5%8C%BA?fromtitle=%E6%8A%9A%E4%BB%99%E6%B9%96%E6%99%AF%E5%8C%BA&fromid=9207389&fromModule=lemma_search-box",
  },
  {
    school: "玉溪第二职业高级中学",
    area: "玉溪校区周边",
    tag1: "郑氏美食(人民路)",
    tag1url: "https://www.xiaohongshu.com/explore/63fd81be000000002701314b",
    tag2: "月亮湾湿地公园    ",
    tag2url:
      "https://baike.baidu.com/item/%E6%A2%85%E5%AD%90%E6%B9%96%E5%85%AC%E5%9B%AD%20?fromModule=lemma_search-box",
  },
  {
    school: "玉溪师范学院",
    area: "玉溪校区周边",
    tag1: "",
    tag1url: "",
    tag2: "九龙池     ",
    tag2url:
      "https://baike.baidu.com/item/%E6%A2%85%E5%AD%90%E6%B9%96%E5%85%AC%E5%9B%AD%20?fromModule=lemma_search-box",
  },
  {
    school: "云南民族大学应用技术学院",
    area: "玉溪校区周边",
    tag1: "",
    tag1url: "",
    tag2: "江川孤山风景区",
    tag2url:
      "https://baike.baidu.com/item/%E7%8E%89%E6%BA%AA%E5%AD%A4%E5%B1%B1/155343?fromModule=search-result_lemma-recommend",
  },
  {
    school: "云南省玉溪工业财贸学校",
    area: "玉溪校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "丽江市民族中等专业学校",
    area: "丽江校区周边",
    tag1: "大研古镇",
    tag1url:
      "https://baike.baidu.com/item/%E4%B8%BD%E6%B1%9F%E5%8F%A4%E5%9F%8E/304665?fromtitle=%E5%A4%A7%E7%A0%94%E5%8F%A4%E9%95%87&fromid=2781935&fromModule=lemma_search-box",
    tag2: "大研古镇",
    tag2url:
      "https://baike.baidu.com/item/%E4%B8%BD%E6%B1%9F%E5%8F%A4%E5%9F%8E/304665?fromtitle=%E5%A4%A7%E7%A0%94%E5%8F%A4%E9%95%87&fromid=2781935&fromModule=lemma_search-box",
  },
  {
    school: "丽江文化旅游学院",
    area: "丽江校区周边",
    tag1: "束河古镇",
    tag1url:
      "https://baike.baidu.com/item/%E6%9D%9F%E6%B2%B3%E8%8C%B6%E9%A9%AC%E5%8F%A4%E9%95%87/10087081?fromModule=search-result_lemma-recommend",
    tag2: "束河古镇",
    tag2url:
      "https://baike.baidu.com/item/%E6%9D%9F%E6%B2%B3%E8%8C%B6%E9%A9%AC%E5%8F%A4%E9%95%87/10087081?fromModule=search-result_lemma-recommend",
  },
  {
    school: "丽江师范高等专科学校",
    area: "丽江校区周边",
    tag1: "花马街",
    tag1url:
      "https://baike.baidu.com/item/%E8%8A%B1%E9%A9%AC%E8%A1%97%20%20%20?fromModule=lemma_search-box",
    tag2: "白沙古镇",
    tag2url:
      "https://baike.baidu.com/item/%E7%99%BD%E6%B2%99%E5%8F%A4%E9%95%87/9178334?fromModule=lemma_search-box",
  },
  {
    school: "曲靖市麒麟区职业技术学校",
    area: "曲靖校区周边",
    tag1: "源缘源美食城",
    tag1url:
      "https://map.baidu.com/poi/%E9%A3%9E%E9%BE%99%E5%A4%A7%E5%8E%A6/@11555658.515,2918511.16,19z?uid=4bb049d9a544444699005067&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
    tag2: "段氏与三十七部会盟碑",
    tag2url:
      "https://baike.baidu.com/item/%E6%AE%B5%E6%B0%8F%E4%B8%8E%E4%B8%89%E5%8D%81%E4%B8%83%E9%83%A8%E4%BC%9A%E7%9B%9F%E7%A2%91?fromModule=lemma_search-box",
  },
  {
    school: "曲靖财经学校",
    area: "曲靖校区周边",
    tag1: "麒麟美食城",
    tag1url:
      "https://ditu.so.com/?k=%E6%9B%B2%E9%9D%96%E7%BE%8E%E9%A3%9F%E4%B8%80%E6%9D%A1%E8%A1%97&c=%E6%98%86%E6%98%8E%E5%B8%82&src=seo",
    tag2: "爨宝子碑",
    tag2url:
      "https://baike.baidu.com/item/%E7%88%A8%E5%AE%9D%E5%AD%90%E7%A2%91/2388845?fr=ge_ala",
  },
  {
    school: "云南能源职业技术学院",
    area: "曲靖校区周边",
    tag1: "康桥记忆美食城",
    tag1url:
      "https://map.baidu.com/poi/%E5%BA%B7%E6%A1%A5%E8%AE%B0%E5%BF%86%E7%BE%8E%E9%A3%9F%E8%A1%97/@11556028.465,2918452.32,19z?uid=0b4b52287547bb5afa42b225&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
    tag2: "白石江公园",
    tag2url:
      "https://baike.baidu.com/item/%E7%99%BD%E7%9F%B3%E6%B1%9F%E5%85%AC%E5%9B%AD/12606294?fr=ge_ala",
  },
  {
    school: "曲靖师范学院",
    area: "曲靖校区周边",
    tag1: "九三美食城",
    tag1url:
      "https://map.baidu.com/poi/%E4%B9%9D%E4%B8%89%E7%BE%8E%E5%BC%8F%E5%9F%8E(%E9%95%BF%E5%85%B4%E8%B7%AF%E5%BA%97)/@11555335.335,2916882.01,19z?uid=9aa7470bba3cebc2bda24021&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
    tag2: "罗平油菜花海",
    tag2url:
      "https://baike.baidu.com/item/%E7%BD%97%E5%B9%B3%E6%B2%B9%E8%8F%9C%E8%8A%B1/2915832?fr=ge_ala",
  },
  {
    school: "云南工业技师学院",
    area: "曲靖校区周边",
    tag1: "",
    tag1url: "",
    tag2: "多依河风景区",
    tag2url:
      "https://baike.baidu.com/item/%E5%A4%9A%E4%BE%9D%E6%B2%B3%E9%A3%8E%E6%99%AF%E5%8C%BA?fromModule=search-result_lemma-recommend",
  },
  {
    school: "曲靖技师学院",
    area: "曲靖校区周边",
    tag1: "",
    tag1url: "",
    tag2: "鲁布革三峡",
    tag2url:
      "https://baike.baidu.com/item/%E9%B2%81%E5%B8%83%E9%9D%A9%E4%B8%89%E5%B3%A1%E9%A3%8E%E6%99%AF%E5%8C%BA/17405290?fromtitle=%E9%B2%81%E5%B8%83%E9%9D%A9%E4%B8%89%E5%B3%A1&fromid=3918354",
  },
  {
    school: "曲靖医学高等专科学校",
    area: "曲靖校区周边",
    tag1: "",
    tag1url: "",
    tag2: "九龙瀑布群",
    tag2url:
      "https://baike.baidu.com/item/%E7%BD%97%E5%B9%B3%E4%B9%9D%E9%BE%99%E7%80%91%E5%B8%83%E7%BE%A4/12610693?fromModule=lemma_search-box&fromtitle=%E4%B9%9D%E9%BE%99%E7%80%91%E5%B8%83%E7%BE%A4&fromid=13346837",
  },
  {
    school: "曲靖应用技术学校",
    area: "曲靖校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "曲靖农业学校",
    area: "曲靖校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "工商职业技术学校",
    area: "曲靖校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "楚雄师范学院",
    area: "楚雄校区周边",
    tag1: "楚雄烧烤小吃一条街",
    tag1url:
      "https://map.baidu.com/poi/%E6%A5%9A%E9%9B%84%E7%83%A7%E7%83%A4%E5%B0%8F%E5%90%83%E4%B8%80%E6%9D%A1%E8%A1%97/@11305698.005,2861359.69,19z?uid=131287a25cef6309a1046449&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
    tag2: "彝人古镇  ",
    tag2url:
      "https://baike.baidu.com/item/%E5%BD%9D%E4%BA%BA%E5%8F%A4%E9%95%87?fromModule=lemma_search-box",
  },
  {
    school: "",
    area: "楚雄校区周边",
    tag1: "兆顺 \n",
    tag1url:
      "https://map.baidu.com/poi/%E5%85%86%E9%A1%BA%E8%B4%A2%E5%AF%8C%E4%B8%AD%E5%BF%83/@11303233.855,2863104.35,19z?uid=bf548e3b0128c7ab6fa3cce2&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
    tag2: "新天地步行街",
    tag2url:
      "https://map.baidu.com/poi/%E6%A5%9A%E9%9B%84%E6%98%9F%E5%A4%A9%E5%9C%B0%E5%95%86%E4%B8%9A%E6%AD%A5%E8%A1%8C%E8%A1%97/@11304767.245,2862552.28,19z?uid=f9fd60629a535c1e0f0e6b36&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
  },
  {
    school: "楚雄技师学院",
    area: "楚雄校区周边",
    tag1: "彝人古镇",
    tag1url:
      "https://baike.baidu.com/item/%E5%BD%9D%E4%BA%BA%E5%8F%A4%E9%95%87?fromModule=lemma_search-box",
    tag2: "彝海公园 ",
    tag2url:
      "https://baike.baidu.com/item/%E5%BD%9D%E6%B5%B7%E5%85%AC%E5%9B%AD%20%20?fromModule=lemma_search-box",
  },
  {
    school: "楚雄高等医学专科学校",
    area: "楚雄校区周边",
    tag1: "",
    tag1url: "",
    tag2: "府后街",
    tag2url:
      "https://map.baidu.com/poi/%E5%BA%9C%E5%90%8E%E8%A1%97/@11304573.625,2862758.83,19z?uid=4110627d2e2bce8caa059d2f&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
  },
  {
    school: "红河学院",
    area: "红河校区周边",
    tag1: "蒙自大食代美食城",
    tag1url: "https://you.ctrip.com/food/mengzi1201/20486028-sight.html",
    tag2: "碧色寨站",
    tag2url:
      "https://baike.baidu.com/item/%E7%A2%A7%E8%89%B2%E5%AF%A8%E7%AB%99?fromModule=lemma_search-box",
  },
  {
    school: "红河卫生职业学院",
    area: "红河校区周边",
    tag1: "邹记虾兵蟹将美食城个旧店",
    tag1url:
      "https://map.baidu.com/poi/%E5%91%A8%E8%AE%B0%E8%99%BE%E5%85%B5%E8%9F%B9%E5%B0%86%E7%BE%8E%E9%A3%9F%E5%9F%8E(%E4%B8%AA%E6%97%A7%E5%BA%97)/@11483954.680569576,2659638.5972152115,19z?uid=ed34b9f162fedfaaa4da394f&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
    tag2: " 碧色寨 ",
    tag2url:
      "https://baike.baidu.com/item/%E7%A2%A7%E8%89%B2%E5%AF%A8/3209472?fr=ge_ala",
  },
  {
    school: "红河职业技术学院",
    area: "红河校区周边",
    tag1: "龙园美食城",
    tag1url:
      "https://map.baidu.com/search/%E7%BA%A2%E6%B2%B3%E9%BE%99%E5%9B%AD%E7%BE%8E%E9%A3%9F%E5%9F%8E/@11429994.518931888,2705871.79,10.83z?querytype=con&wd=%E7%BA%A2%E6%B2%B3%E9%BE%99%E5%9B%AD%E7%BE%8E%E9%A3%9F%E5%9F%8E&c=107&provider=pc-aladin&pn=0&device_ratio=2&da_src=shareurl",
    tag2: "南湖公园",
    tag2url:
      "https://baijiahao.baidu.com/s?id=1769478079056130177&wfr=spider&for=pc",
  },
  {
    school: "蒙自警星学校",
    area: "红河校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "民族师范",
    area: "红河校区周边",
    tag1: "",
    tag1url: "",
    tag2: "朱家花园",
    tag2url:
      "https://baike.baidu.com/item/%E6%9C%B1%E5%AE%B6%E8%8A%B1%E5%9B%AD/3433801?fr=ge_ala",
  },
  {
    school: "",
    area: "红河校区周边",
    tag1: "",
    tag1url: "",
    tag2: "建水文庙 ",
    tag2url:
      "https://baike.baidu.com/item/%E5%BB%BA%E6%B0%B4%E6%96%87%E5%BA%99/3433626?fr=ge_ala",
  },
  {
    school: "",
    area: "红河校区周边",
    tag1: "",
    tag1url: "",
    tag2: "燕子洞",
    tag2url:
      "https://baike.baidu.com/item/%E7%87%95%E5%AD%90%E6%B4%9E/8282643?fr=ge_ala",
  },
  {
    school: "云南锡业职业技术学院",
    area: "红河校区周边",
    tag1: "",
    tag1url: "",
    tag2: "丫沙底瀑布温泉",
    tag2url:
      "https://baike.baidu.com/item/%E4%B8%AB%E6%B2%99%E5%BA%95%E7%80%91%E5%B8%83%E6%B8%A9%E6%B3%89?fromModule=lemma_search-box",
  },
  {
    school: "",
    area: "红河校区周边",
    tag1: "",
    tag1url: "",
    tag2: " 沙甸大清真寺",
    tag2url:
      "https://baike.baidu.com/item/%E6%B2%99%E7%94%B8%E5%A4%A7%E6%B8%85%E7%9C%9F%E5%AF%BA?fromModule=lemma_search-box",
  },
  {
    school: "",
    area: "红河校区周边",
    tag1: "",
    tag1url: "",
    tag2: "金湖公园",
    tag2url: "https://bbs.youxiake.com/baike/14219_100.html",
  },
  {
    school: "",
    area: "红河校区周边",
    tag1: "",
    tag1url: "",
    tag2: "个碧石铁路公司旧址",
    tag2url: "http://www.k1u.com/jingdian/85930.html",
  },
  {
    school: "",
    area: "红河校区周边",
    tag1: "",
    tag1url: "",
    tag2: "宝华公园",
    tag2url:
      "https://baike.baidu.com/item/%E5%AE%9D%E5%8D%8E%E5%85%AC%E5%9B%AD/17398631?fr=ge_ala",
  },
  {
    school: "",
    area: "红河校区周边",
    tag1: "",
    tag1url: "",
    tag2: "绿水河",
    tag2url:
      "https://baike.baidu.com/item/%E7%BB%BF%E6%B0%B4%E6%B2%B3?fromModule=lemma_search-box",
  },
  {
    school: "",
    area: "红河校区周边",
    tag1: "",
    tag1url: "",
    tag2: "玻璃栈道",
    tag2url:
      "https://m.ctrip.com/webapp/you/tripshoot/paipai/detail/detail?articleId=16626841&isHideNavBar=YES&seo=0&twojumpwakeup=0&allianceId=1049189&sid=19855591",
  },
  {
    school: "",
    area: "红河校区周边",
    tag1: "",
    tag1url: "",
    tag2: "老阴山",
    tag2url:
      "https://baike.baidu.com/item/%E8%80%81%E9%98%B4%E5%B1%B1/225731?fr=ge_ala",
  },
  {
    school: "",
    area: "红河校区周边",
    tag1: "",
    tag1url: "",
    tag2: "加级寨梨花谷",
    tag2url:
      "https://baijiahao.baidu.com/s?id=1761514724232787943&wfr=spider&for=pc",
  },
  {
    school: "",
    area: "红河校区周边",
    tag1: "",
    tag1url: "",
    tag2: "云庙",
    tag2url:
      "https://baike.baidu.com/item/%E4%BA%91%E5%BA%99/11069499?fr=ge_ala",
  },
  {
    school: "云南红河技师学院",
    area: "红河校区周边",
    tag1: "",
    tag1url: "",
    tag2: "泸江河 ",
    tag2url:
      "https://m.ctrip.com/webapp/you/tripshoot/paipai/detail/detail?articleId=15667473&isHideNavBar=YES&seo=0&twojumpwakeup=0&allianceId=1049189&sid=19855591",
  },
  {
    school: "",
    area: "红河校区周边",
    tag1: "",
    tag1url: "",
    tag2: "七孔桥 ",
    tag2url:
      "https://m.ctrip.com/webapp/you/tripshoot/paipai/detail/detail?articleId=15667473&isHideNavBar=YES&seo=0&twojumpwakeup=0&allianceId=1049189&sid=19855591",
  },
  {
    school: "",
    area: "红河校区周边",
    tag1: "",
    tag1url: "",
    tag2: "南洞 ",
    tag2url:
      "https://m.ctrip.com/webapp/you/tripshoot/paipai/detail/detail?articleId=15667473&isHideNavBar=YES&seo=0&twojumpwakeup=0&allianceId=1049189&sid=19855591",
  },
  {
    school: "",
    area: "红河校区周边",
    tag1: "",
    tag1url: "",
    tag2: "大塔",
    tag2url:
      "https://m.ctrip.com/webapp/you/tripshoot/paipai/detail/detail?articleId=15667473&isHideNavBar=YES&seo=0&twojumpwakeup=0&allianceId=1049189&sid=19855591",
  },
  {
    school: "云南建设学校",
    area: "大理校区周边",
    tag1: "昆百大 \n",
    tag1url:
      "https://map.baidu.com/poi/%E5%A4%A7%E7%90%86%E6%98%86%E7%99%BE%E5%A4%A7%E6%B3%B0%E4%B8%9A%E5%9F%8E/@11157891.465,2929926.64,19z?uid=6f4e33fd23fb99018605bfeb&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
    tag2: "大理古城    ",
    tag2url:
      "https://baike.baidu.com/item/%E5%A4%A7%E7%90%86%E5%8F%A4%E5%9F%8E/1874828?fr=ge_ala",
  },
  {
    school: "滇西应用技术大学",
    area: "大理校区周边",
    tag1: "大理古城 \n",
    tag1url:
      "https://baike.baidu.com/item/%E5%A4%A7%E7%90%86%E5%8F%A4%E5%9F%8E/1874828?fromModule=disambiguation",
    tag2: "时代天街 ",
    tag2url:
      "https://map.baidu.com/?newmap=1&s=inf%26uid%3D4a452a0116455b529122e01a%26wd%3D%E5%A4%A7%E7%90%86%E6%81%92%E9%82%A6%E6%97%B6%E4%BB%A3%E5%A4%A9%E8%A1%97%26all%3D1%26c%3D104&from=alamap&tpl=map_singlepoint",
  },
  {
    school: "大理技师学院",
    area: "大理校区周边",
    tag1: "大关邑商业街 \n",
    tag1url:
      "https://map.baidu.com/poi/%E5%A4%A7%E5%85%B3%E9%82%91%E5%95%86%E4%B8%9A%E8%A1%97/@11158225.205,2931990.11,19z?uid=4385ddfb100ce75979b55c28&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
    tag2: "昆百大    ",
    tag2url:
      "https://mbd.baidu.com/newspage/data/dtlandingsuper?nid=dt_4005591418515255811",
  },
  {
    school: "大理州财贸学校",
    area: "大理校区周边",
    tag1: "锦生美食城 \n",
    tag1url:
      "https://map.baidu.com/poi//@12853736.31,2609137.25,13z?info_merge=1&ugc_type=3&ugc_ver=1&querytype=detailConInfo&searchseo=1&uid=88abd852c7305cb728d7abfb",
    tag2: "下关兴盛桥  ",
    tag2url:
      "https://map.baidu.com/poi/%E5%A4%A7%E5%85%B3%E9%82%91%E5%95%86%E4%B8%9A%E8%A1%97/@11158224.205,2931990.11,19z?uid=4385ddfb100ce75979b55c28&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
  },
  {
    school: "大理大学（荷花校区）",
    area: "大理校区周边",
    tag1: "南谷小吃街 \n",
    tag1url:
      "https://map.baidu.com/poi/%E9%B8%B3%E6%B5%A6%E5%8D%97%E8%B0%B7%E5%B0%8F%E5%90%83%E8%A1%97/@11157350.815,2930104.06,19z?uid=517a5f374f3ba42372455bb6&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
    tag2: "大关邑商业街",
    tag2url: "https://weibo.com/u/7637086000?is_all=1",
  },
  {
    school: "大理大学（古城校区）",
    area: "大理校区周边",
    tag1: "小关邑 \n",
    tag1url:
      "https://baike.baidu.com/item/%E5%B0%8F%E5%85%B3%E9%82%91%E8%87%AA%E7%84%B6%E6%9D%91/6828072?fr=ge_ala",
    tag2: "爱情海购物广场",
    tag2url:
      "https://baike.baidu.com/item/%E6%B4%B1%E6%B5%B7%E5%85%AC%E5%9B%AD/8335149?fr=ge_ala",
  },
  {
    school: "大理护理职业学院",
    area: "大理校区周边",
    tag1: "时代天街 \n",
    tag1url:
      "https://map.baidu.com/poi/%E5%A4%A7%E7%90%86%E6%81%92%E9%82%A6%E6%97%B6%E4%BB%A3%E5%A4%A9%E8%A1%97/@11158064.955,2929956.64,19z?uid=4a452a0116455b529122e01a&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
    tag2: "洱海公园 ",
    tag2url: "https://baike.baidu.com/item/%E8%8B%8D%E5%B1%B1/15371",
  },
  {
    school: "大理市中等职业学校",
    area: "大理校区周边",
    tag1: "正阳时代广场",
    tag1url:
      "https://baike.baidu.com/item/%E6%AD%A3%E9%98%B3%E6%97%B6%E4%BB%A3%E5%B9%BF%E5%9C%BA/60486862?fr=ge_ala",
    tag2: "苍山",
    tag2url: "",
  },
  {
    school: "大理农林职业技术学院",
    area: "大理校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "保山学院",
    area: "保山校区周边",
    tag1: "五洲国际广场",
    tag1url:
      "https://map.baidu.com/poi/%E4%BF%9D%E5%B1%B1%E4%BA%94%E6%B4%B2%E5%9B%BD%E9%99%85%E5%B9%BF%E5%9C%BA/@11040877.685,2874624.34,19z?uid=e198ef94e6dda60333f71d59&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
    tag2: "哀牢古国",
    tag2url:
      "https://baike.baidu.com/item/%E5%93%80%E7%89%A2%E5%9B%BD?fromtitle=%E5%93%80%E7%89%A2%E5%8F%A4%E5%9B%BD&fromid=2027723&fromModule=lemma_search-box",
  },
  {
    school: "保山中医药高等专科学校",
    area: "保山校区周边",
    tag1: "三馆广场",
    tag1url:
      "https://baike.baidu.com/item/%E4%BF%9D%E5%B1%B1%E4%B8%89%E9%A6%86/2912270?fr=ge_ala",
    tag2: "马里商业中心",
    tag2url:
      "https://map.baidu.com/search/%E4%BF%9D%E5%B1%B1%E9%A9%AC%E9%87%8C%E5%95%86%E4%B8%9A%E4%B8%AD%E5%BF%83/@11039580.185638484,2872027.3423885,19z?querytype=con&wd=%E4%BF%9D%E5%B1%B1%E9%A9%AC%E9%87%8C%E5%95%86%E4%B8%9A%E4%B8%AD%E5%BF%83&c=112&provider=pc-aladin&pn=0&device_ratio=2&da_src=shareurl",
  },
  {
    school: "保山技师学院",
    area: "保山校区周边",
    tag1: "盛世水景步行街",
    tag1url: "https://ditu.so.com/?pid=84e5b5124ce92c5f&src=seo",
    tag2: "盛世水景步行街",
    tag2url: "https://ditu.so.com/?pid=84e5b5124ce92c5f&src=seo",
  },
  {
    school: "腾冲第一职业技术学校",
    area: "保山校区周边",
    tag1: "东来夜市",
    tag1url:
      "https://baijiahao.baidu.com/s?id=1763514753836321616&wfr=spider&for=pc",
    tag2: "五洲国际广场",
    tag2url:
      "https://map.baidu.com/poi/%E4%BF%9D%E5%B1%B1%E4%BA%94%E6%B4%B2%E5%9B%BD%E9%99%85%E5%B9%BF%E5%9C%BA/@11040877.685,2874624.34,19z?uid=e198ef94e6dda60333f71d59&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
  },
  {
    school: "滇西应用技术大学珠宝学院",
    area: "保山校区周边",
    tag1: "林云夜市",
    tag1url: "https://www.sohu.com/a/673954190_121687424",
    tag2: "和顺古镇",
    tag2url:
      "https://baike.baidu.com/item/%E5%92%8C%E9%A1%BA%E9%95%87/38143?fromtitle=%E5%92%8C%E9%A1%BA%E5%8F%A4%E9%95%87&fromid=10032760&fromModule=lemma_search-box",
  },
  {
    school: "昌宁职校",
    area: "保山校区周边",
    tag1: "和顺古镇",
    tag1url:
      "https://baike.baidu.com/item/%E5%92%8C%E9%A1%BA%E9%95%87/38143?fromtitle=%E5%92%8C%E9%A1%BA%E5%8F%A4%E9%95%87&fromid=10032760&fromModule=lemma_search-box",
    tag2: "天成商业街",
    tag2url:
      "https://map.baidu.com/poi/%E5%A4%A9%E6%88%90%E5%95%86%E4%B8%9A%E8%A1%97/@10965081.405,2861563.33,19z?uid=e71b7eccac0dcb77f503b856&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
  },
  {
    school: "施甸县职业高级中学",
    area: "保山校区周边",
    tag1: "天成商业街",
    tag1url:
      "https://map.baidu.com/poi/%E5%A4%A9%E6%88%90%E5%95%86%E4%B8%9A%E8%A1%97/@10965081.405,2861563.33,19z?uid=e71b7eccac0dcb77f503b856&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
    tag2: "万佳商业广场",
    tag2url:
      "https://map.baidu.com/poi/%E4%B8%87%E4%BD%B3%E5%95%86%E4%B8%9A%E5%B9%BF%E5%9C%BA/@10965733.565,2860932.94,19z?uid=658188f4eefe46fc4bcc4a65&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
  },
  {
    school: "龙陵县职业高级中学",
    area: "保山校区周边",
    tag1: "万佳商业广场",
    tag1url:
      "https://map.baidu.com/poi/%E4%B8%87%E4%BD%B3%E5%95%86%E4%B8%9A%E5%B9%BF%E5%9C%BA/@10965733.565,2860932.94,19z?uid=658188f4eefe46fc4bcc4a65&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
    tag2: "",
    tag2url: "",
  },
  {
    school: "临沧市农业学校",
    area: "临沧校区周边",
    tag1: "学府美食城                            ",
    tag1url:
      "https://map.baidu.com/poi/%E5%AD%A6%E5%BA%9C%E7%BE%8E%E9%A3%9F%E5%9F%8E/@11141604.085,2722912.89,19z?uid=88a177d095ecc56d57bfff22&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
    tag2: "西门公园      ",
    tag2url:
      "https://baike.baidu.com/item/%E4%B8%B4%E6%B2%A7%E8%A5%BF%E9%97%A8%E5%85%AC%E5%9B%AD?fromModule=lemma_search-box",
  },
  {
    school: "临沧市财贸学校",
    area: "临沧校区周边",
    tag1: "百树广场                          ",
    tag1url:
      "https://map.baidu.com/poi/%E7%99%BE%E6%A0%91%E5%B9%BF%E5%9C%BA/@11142164.535,2722029.92,19z?uid=3e7d2e3ba43eb7e7725e8005&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
    tag2: "百树广场      ",
    tag2url:
      "https://map.baidu.com/poi/%E7%99%BE%E6%A0%91%E5%B9%BF%E5%9C%BA/@11142164.535,2722029.92,19z?uid=3e7d2e3ba43eb7e7725e8005&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
  },
  {
    school: "云南省临沧卫生学校",
    area: "临沧校区周边",
    tag1: "临沧民族特色美食城     ",
    tag1url:
      "https://map.baidu.com/search/%E4%B8%B4%E6%B2%A7%E6%B0%91%E6%97%8F%E7%89%B9%E8%89%B2%E7%BE%8E%E9%A3%9F%E5%9F%8E/@11141466.635000002,2722453.08,19z?querytype=con&wd=%E4%B8%B4%E6%B2%A7%E6%B0%91%E6%97%8F%E7%89%B9%E8%89%B2%E7%BE%8E%E9%A3%9F%E5%9F%8E&c=110&provider=pc-aladin&pn=0&device_ratio=1&da_src=shareurl",
    tag2: "临沧茶马古镇  ",
    tag2url:
      "https://map.baidu.com/search/%E4%B8%B4%E6%B2%A7%E8%8C%B6%E9%A9%AC%E5%8F%A4%E9%95%87/@11143645.285038898,2720172.3409187,16.97z?querytype=s&da_src=shareurl&wd=%E4%B8%B4%E6%B2%A7%E8%8C%B6%E9%A9%AC%E5%8F%A4%E9%95%87&c=110&src=0&pn=0&sug=0&l=19&b=(11142382.705,2722738.78;11143038.205,2723067.28)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&device_ratio=1",
  },
  {
    school: "临沧高级技工学校",
    area: "临沧校区周边",
    tag1: "缅临夜市        ",
    tag1url: "https://www.xiaohongshu.com/explore/5fa5290e000000000100643e",
    tag2: "沧江园广场   ",
    tag2url:
      "https://map.baidu.com/search/%E6%B2%A7%E6%B1%9F%E5%9B%AD%E5%B9%BF%E5%9C%BA/@11142710.455,2722903.03,19z?querytype=s&da_src=shareurl&wd=%E6%B2%A7%E6%B1%9F%E5%9B%AD%E5%B9%BF%E5%9C%BA&c=110&src=0&pn=0&sug=0&l=9&b=(10855845.449901257,2618654.645529623;11227892.42171454,2805103.814470377)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&device_ratio=1",
  },
  {
    school: "滇西科技师范学院",
    area: "临沧校区周边",
    tag1: "",
    tag1url: "",
    tag2: "凯歌时代广场",
    tag2url:
      "https://map.baidu.com/poi/%E5%87%AF%E6%AD%8C%E6%97%B6%E4%BB%A3%E5%B9%BF%E5%9C%BA/@11142871.045,2722892.56,19z?uid=fdec8bead61ff59a48cc4a7a&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
  },
  {
    school: "职业技术学院",
    area: "临沧校区周边",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "文山学院",
    area: "文山",
    tag1: "味道文山光大美食街",
    tag1url:
      "https://map.baidu.com/search/%E5%91%B3%E9%81%93%E6%96%87%E5%B1%B1%E5%85%89%E5%A4%A7%E7%BE%8E%E9%A3%9F%E8%A1%97/@11605841.177686116,2660215.19,19z?querytype=con&wd=%E5%91%B3%E9%81%93%E6%96%87%E5%B1%B1%E5%85%89%E5%A4%A7%E7%BE%8E%E9%A3%9F%E8%A1%97&c=177&provider=pc-aladin&pn=0&device_ratio=1&da_src=shareurl",
    tag2: "文山老君山 ",
    tag2url:
      "https://baike.baidu.com/item/%E6%96%87%E5%B1%B1%E5%B8%82%E8%80%81%E5%90%9B%E5%B1%B1%E9%A3%8E%E6%99%AF%E5%90%8D%E8%83%9C%E5%8C%BA/62838851?fromModule=search-result_lemma-recommend",
  },
  {
    school: "三鑫技术学院",
    area: "文山",
    tag1: "",
    tag1url: "",
    tag2: "丘北舍得草场  ",
    tag2url: "https://you.ctrip.com/sight/qiubei2175/5072397.html",
  },
  {
    school: "职教园区（技校）",
    area: "文山",
    tag1: "",
    tag1url: "",
    tag2: "广南三腊瀑布   ",
    tag2url:
      "https://baike.baidu.com/item/%E4%B8%89%E8%85%8A%E7%80%91%E5%B8%83/7042753?fr=ge_ala",
  },
  {
    school: "职教园区（民师）",
    area: "文山",
    tag1: "",
    tag1url: "",
    tag2: "富宁坡芽村 ",
    tag2url:
      "https://baike.baidu.com/item/%E5%9D%A1%E8%8A%BD%E6%9D%91/154650?fromModule=lemma-qiyi_sense-lemma",
  },
  {
    school: "职教园区（财校）",
    area: "文山",
    tag1: "",
    tag1url: "",
    tag2: "麻栗坡天保口岸 ",
    tag2url: "https://wenshan.cncn.com/jingdian/tianbaokouan/",
  },
  {
    school: "职教园区（卫校）",
    area: "文山",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "职教园区（工商）",
    area: "文山",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "职教园区（农校）",
    area: "文山",
    tag1: "",
    tag1url: "",
    tag2: "",
    tag2url: "",
  },
  {
    school: "普洱学院",
    area: "普洱校区周边",
    tag1: "最普洱美食街                               ",
    tag1url:
      "https://map.baidu.com/poi/%E6%9C%80%E6%99%AE%E6%B4%B1%E7%BE%8E%E9%A3%9F%E8%A1%97/@11241851.68,2589324.48,10z?uid=59a1d154a16c8142b817e747&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
    tag2: "普洱太阳河森林公园 ",
    tag2url: "https://www-monitor.mafengwo.cn/poi/7055143.html",
  },
  {
    school: "云南农业大学热带作物学院",
    area: "普洱校区周边",
    tag1: "兰花美食街",
    tag1url:
      "https://map.baidu.com/search/%E6%99%AE%E6%B4%B1%E5%85%B0%E8%8A%B1%E7%BE%8E%E9%A3%9F%E8%A1%97/@11240605.414831264,2588830.0426526726,14.98z?querytype=s&da_src=shareurl&wd=%E6%99%AE%E6%B4%B1%E5%85%B0%E8%8A%B1%E7%BE%8E%E9%A3%9F%E8%A1%97&c=28&src=0&pn=0&sug=0&l=9&b=(11057393.56182256,2532280.260243817;11448946.329567887,2728504.644994909)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&device_ratio=1",
    tag2: "墨江北回归线标志园 ",
    tag2url:
      "https://baike.baidu.com/item/%E5%A2%A8%E6%B1%9F%E5%8C%97%E5%9B%9E%E5%BD%92%E7%BA%BF%E6%A0%87%E5%BF%97%E5%9B%AD?fromModule=lemma_search-box",
  },
  {
    school: "云南省普洱卫生学校",
    area: "普洱校区周边",
    tag1: "",
    tag1url: "",
    tag2: "碧溪古镇       ",
    tag2url:
      "https://baike.baidu.com/item/%E7%A2%A7%E6%BA%AA%E5%8F%A4%E9%95%87?fromModule=lemma_search-box",
  },
  {
    school: "",
    area: "普洱校区周边",
    tag1: "",
    tag1url: "",
    tag2: "梅子湖公园",
    tag2url:
      "https://baike.baidu.com/item/%E6%A2%85%E5%AD%90%E6%B9%96%E5%85%AC%E5%9B%AD%20?fromModule=lemma_search-box",
  },
  {
    school: "",
    area: "普洱校区周边",
    tag1: "",
    tag1url: "",
    tag2: "西盟龙潭（勐梭龙潭）",
    tag2url:
      "https://baike.baidu.com/item/%E5%8B%90%E6%A2%AD%E9%BE%99%E6%BD%AD?fromModule=lemma_search-box",
  },
  {
    school: "德宏师范高等专科学校",
    area: "德宏校区周边",
    tag1: "1.鸭霸美食城                                   ",
    tag1url:
      "https://map.baidu.com/poi/%E9%B8%AD%E9%9C%B8%E7%BE%8E%E9%A3%9F%E5%9F%8E(%E7%91%9E%E6%B1%9F%E8%B7%AF%E5%BA%97)/@10894851.685,2736464.58,19z?uid=e6e6a035f5ea3da5f42310ec&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
    tag2: "莫里热带雨林   ",
    tag2url:
      "https://baike.baidu.com/item/%E8%8E%AB%E9%87%8C%E7%83%AD%E5%B8%A6%E9%9B%A8%E6%9E%97?fromModule=lemma_search-box",
  },
  {
    school: "德宏职业学院",
    area: "德宏校区周边",
    tag1: "2.瑞丽边贸街",
    tag1url: "",
    tag2: "树包塔     ",
    tag2url:
      "https://baike.baidu.com/item/%E6%A0%91%E5%8C%85%E5%A1%94?fromModule=lemma_search-box",
  },
  {
    school: "芒市职业教育中心",
    area: "德宏校区周边",
    tag1: "",
    tag1url:
      "https://baike.baidu.com/item/%E7%91%9E%E4%B8%BD%E8%BE%B9%E8%B4%B8%E8%A1%97?fromModule=lemma_search-box",
    tag2: "勐巴娜西珍奇园",
    tag2url:
      "https://baike.baidu.com/item/%E5%8B%90%E5%B7%B4%E5%A8%9C%E8%A5%BF%E7%8F%8D%E5%A5%87%E5%9B%AD?fromModule=lemma_search-box",
  },
  {
    school: "西双版纳职业技术学院（勐仑校区）",
    area: "西双版纳校区周边",
    tag1: "文化小吃                            ",
    tag1url: "https://baijiahao.baidu.com/s?id=1750097546057264028",
    tag2: "曼听御花园",
    tag2url:
      "https://baike.baidu.com/item/%E6%9B%BC%E5%90%AC%E5%85%AC%E5%9B%AD/6598751?fr=ge_ala",
  },
  {
    school: "西双版纳职业技术学院（老校区）",
    area: "西双版纳校区周边",
    tag1: "景洪港傣家美食园                     ",
    tag1url:
      "https://baike.baidu.com/item/%E5%82%A3%E5%AE%B6%E7%BE%8E%E9%A3%9F%E5%9B%AD/14243883?fr=ge_ala",
    tag2: "告庄西双景",
    tag2url:
      "https://baike.baidu.com/item/%E8%A5%BF%E5%8F%8C%E7%89%88%E7%BA%B3%E6%9C%9B%E5%A4%A9%E6%A0%91/8485397?fr=ge_ala",
  },
  {
    school: "滇西应用技术大学傣医药学院",
    area: "西双版纳校区周边",
    tag1: "美味小厨                        ",
    tag1url:
      "https://baijiahao.baidu.com/s?id=1739572452905016363&wfr=spider&for=pc",
    tag2: "西双版纳原始森林公园",
    tag2url:
      "https://baike.baidu.com/item/%E9%87%8E%E8%B1%A1%E8%B0%B7/874579?fr=ge_ala",
  },
  {
    school: "",
    area: "西双版纳校区周边",
    tag1: "景洪夜市  ",
    tag1url:
      "https://baike.baidu.com/item/%E6%99%AF%E6%B4%AA%E6%B1%9F%E8%BE%B9%E5%A4%9C%E5%B8%82/52309426?fr=ge_ala",
    tag2: "望天树景区",
    tag2url:
      "https://baike.baidu.com/item/%E5%91%8A%E5%BA%84%E8%A5%BF%E5%8F%8C%E6%99%AF?fromModule=lemma_search-box",
  },
  {
    school: "",
    area: "西双版纳校区周边",
    tag1: "",
    tag1url: "",
    tag2: "西双版纳融创傣秀剧场",
    tag2url:
      "https://baike.baidu.com/item/%E5%82%A3%E7%A7%80%E5%89%A7%E5%9C%BA/60648493?fr=ge_ala",
  },
  {
    school: "",
    area: "西双版纳校区周边",
    tag1: "",
    tag1url: "",
    tag2: "勐泐大佛寺",
    tag2url:
      "https://baike.baidu.com/item/%E8%A5%BF%E5%8F%8C%E7%89%88%E7%BA%B3%E5%82%A3%E6%97%8F%E5%9B%AD%E6%99%AF%E5%8C%BA/346433?fr=ge_ala",
  },
  {
    school: "",
    area: "西双版纳校区周边",
    tag1: "",
    tag1url: "",
    tag2: "野象谷",
    tag2url:
      "https://baike.baidu.com/item/%E8%A5%BF%E5%8F%8C%E7%89%88%E7%BA%B3%E5%8E%9F%E5%A7%8B%E6%A3%AE%E6%9E%97%E5%85%AC%E5%9B%AD?fromModule=lemma_search-box",
  },
  {
    school: "",
    area: "西双版纳校区周边",
    tag1: "",
    tag1url: "",
    tag2: "西双版纳傣族园",
    tag2url:
      "https://baike.baidu.com/item/%E5%8B%90%E6%B3%90%E5%A4%A7%E4%BD%9B%E5%AF%BA?fromModule=lemma_search-box",
  },
  {
    school: "",
    area: "西双版纳校区周边",
    tag1: "",
    tag1url: "",
    tag2: "西双版纳总佛",
    tag2url:
      "https://baijiahao.baidu.com/s?id=1765478810247762290&wfr=spider&for=pc",
  },
  {
    school: "云南民族大学香格里拉职业技术学院",
    area: "香格里拉校区周边",
    tag1: "香巴拉旅游小镇美食城               ",
    tag1url:
      "https://map.baidu.com/poi/%E9%A6%99%E5%B7%B4%E6%8B%89%E6%97%85%E6%B8%B8%E5%B0%8F%E9%95%87/@11101399.185,3208311.1,19z?uid=50fb64ffe67a1223e9f85303&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl",
    tag2: "普达措国家森林公园",
    tag2url:
      "https://map.baidu.com/search/%E9%A6%99%E5%B7%B4%E6%8B%89%E5%A4%A7%E9%81%93/@11100086.90425494,3206932.09,16.9z?querytype=con&wd=%E9%A6%99%E5%B7%B4%E6%8B%89%E5%A4%A7%E9%81%93&c=115&provider=pc-aladin&pn=0&device_ratio=1&da_src=shareurl",
  },
  {
    school: "迪庆藏族自治州民族中等专业学校",
    area: "香格里拉校区周边",
    tag1: "香巴拉大道",
    tag1url:
      "https://map.baidu.com/search/%E9%A6%99%E5%B7%B4%E6%8B%89%E5%A4%A7%E9%81%93/@11100086.90425494,3206932.09,16.9z?querytype=con&wd=%E9%A6%99%E5%B7%B4%E6%8B%89%E5%A4%A7%E9%81%93&c=115&provider=pc-aladin&pn=0&device_ratio=1&da_src=shareurl",
    tag2: "虎跳峡景区",
    tag2url:
      "https://map.baidu.com/search/%E9%A6%99%E5%B7%B4%E6%8B%89%E5%A4%A7%E9%81%93/@11100086.90425494,3206932.09,16.9z?querytype=con&wd=%E9%A6%99%E5%B7%B4%E6%8B%89%E5%A4%A7%E9%81%93&c=115&provider=pc-aladin&pn=0&device_ratio=1&da_src=shareurl",
  },
  {
    school: "",
    area: "香格里拉校区周边",
    tag1: "",
    tag1url: "",
    tag2: "松赞林寺",
    tag2url:
      "https://map.baidu.com/search/%E9%A6%99%E5%B7%B4%E6%8B%89%E5%A4%A7%E9%81%93/@11100086.90425494,3206932.09,16.9z?querytype=con&wd=%E9%A6%99%E5%B7%B4%E6%8B%89%E5%A4%A7%E9%81%93&c=115&provider=pc-aladin&pn=0&device_ratio=1&da_src=shareurl",
  },
  {
    school: "",
    area: "香格里拉校区周边",
    tag1: "",
    tag1url: "",
    tag2: "独克宗古城风景区",
    tag2url:
      "https://map.baidu.com/search/%E9%A6%99%E5%B7%B4%E6%8B%89%E5%A4%A7%E9%81%93/@11100086.90425494,3206932.09,16.9z?querytype=con&wd=%E9%A6%99%E5%B7%B4%E6%8B%89%E5%A4%A7%E9%81%93&c=115&provider=pc-aladin&pn=0&device_ratio=1&da_src=shareurl",
  },
  {
    school: "怒江州民族中等专业学校",
    area: "怒江",
    tag1: "怒江西岸夜市和同心广场",
    tag1url:
      "https://map.baidu.com/search/%E6%80%92%E6%B1%9F%E8%A5%BF%E5%B2%B8%E5%A4%9C%E5%B8%82%E5%92%8C%E5%90%8C%E5%BF%83%E5%B9%BF%E5%9C%BA/@11004964.860394904,2959208.2684255503,16.35z?querytype=con&wd=%E6%80%92%E6%B1%9F%E8%A5%BF%E5%B2%B8%E5%A4%9C%E5%B8%82%E5%92%8C%E5%90%8C%E5%BF%83%E5%B9%BF%E5%9C%BA&c=113&provider=pc-aladin&pn=0&device_ratio=2&da_src=shareurl",
    tag2: "芭蕉河",
    tag2url:
      "https://baike.baidu.com/item/%E8%8A%AD%E8%95%89%E6%B2%B3?fromModule=lemma_search-box",
  },
  {
    school: "",
    area: "怒江",
    tag1: "",
    tag1url: "",
    tag2: "片马抗英纪念馆",
    tag2url:
      "https://baike.baidu.com/item/%E7%89%87%E9%A9%AC%E6%8A%97%E8%8B%B1%E7%BA%AA%E5%BF%B5%E9%A6%86/20105268?fr=ge_ala",
  },
  {
    school: "",
    area: "怒江",
    tag1: "",
    tag1url: "",
    tag2: "片马口岸",
    tag2url:
      "https://baike.baidu.com/item/%E7%89%87%E9%A9%AC%E5%8F%A3%E5%B2%B8?fromModule=lemma_search-box",
  },
  {
    school: "",
    area: "怒江",
    tag1: "",
    tag1url: "",
    tag2: "怒江听命湖",
    tag2url:
      "https://baike.baidu.com/item/%E5%90%AC%E5%91%BD%E6%B9%96/3376435?fr=ge_ala",
  },
  {
    school: "",
    area: "怒江",
    tag1: "",
    tag1url: "",
    tag2: "飞来石",
    tag2url:
      "https://baike.baidu.com/item/%E9%A3%9E%E6%9D%A5%E7%9F%B3/23157292?fr=ge_ala",
  },
  {
    school: "",
    area: "怒江",
    tag1: "",
    tag1url: "",
    tag2: "老虎跳峡",
    tag2url: "https://www.sohu.com/a/403437585_120043961",
  },
  {
    school: "昭通职业技术学校",
    area: "昭通",
    tag1: "马思烧烤",
    tag1url: "https://touch.go.qunar.com/poi/20274965",
    tag2: "大山包",
    tag2url:
      "https://baike.sogou.com/v25753589.htm?fromTitle=%E6%98%AD%E9%80%9A%E5%A4%A7%E5%B1%B1%E5%8C%85&ch=frombaikevr",
  },
  {
    school: "昭通学院学校",
    area: "昭通",
    tag1: "琼华缘正宗昭通天麻火腿鸡总店",
    tag1url:
      "https://map.qq.com/?what=%E6%98%AD%E9%80%9A%E7%90%BC%E5%8D%8E%E7%BC%98%E6%AD%A3%E5%AE%97%E6%98%AD%E9%80%9A%E5%A4%A9%E9%BA%BB%E7%81%AB%E8%85%BF%E9%B8%A1%E6%80%BB%E5%BA%97&city=%E6%98%AD%E9%80%9A%E5%B8%82&type=poi&init_view=2&ref=TXZBZ-JFC3P-4NPDJ-L2U3C-VHY7K-NWF4U",
    tag2: "西部大峡谷温泉旅游区",
    tag2url: "https://www.ly.com/go/scenery/382/3820000001792.html",
  },
  {
    school: "昭通卫生职业学院",
    area: "昭通",
    tag1: "小马正宗牛羊肉把把烧",
    tag1url: "https://ditu.so.com/?pid=b6c9a786fc966c9e&src=seo",
    tag2: "豆沙关",
    tag2url:
      "https://map.qq.com/?what=%E6%98%AD%E9%80%9A%E8%B1%86%E6%B2%99%E5%85%B3&city=%E6%98%AD%E9%80%9A%E5%B8%82&type=poi&init_view=2&ref=TXZBZ-JFC3P-4NPDJ-L2U3C-VHY7K-NWF4U",
  },
  {
    school: "昭通卫生职业学院",
    area: "昭通",
    tag1: "盐耕火锅店",
    tag1url:
      "https://map.qq.com/?what=%E6%98%AD%E9%80%9A%E7%9B%90%E8%80%95%E7%81%AB%E9%94%85%E5%BA%97&city=%E6%98%AD%E9%80%9A%E5%B8%82&type=poi&init_view=2&ref=TXZBZ-JFC3P-4NPDJ-L2U3C-VHY7K-NWF4U",
    tag2: "观音阁",
    tag2url:
      "https://baike.sogou.com/v64342218.htm?fromTitle=%E8%A7%82%E9%9F%B3%E9%98%81%EF%BC%88%E4%BA%91%E5%8D%97%E7%9C%81%E7%9B%90%E6%B4%A5%E5%8E%BF%E8%A7%82%E9%9F%B3%E9%98%81%EF%BC%89&ch=frombaikevr",
  },
  {
    school: "",
    area: "昭通",
    tag1: "青年路油糕饵块",
    tag1url: "https://www.xiaohongshu.com/explore/611f0d7f0000000021039e8e",
    tag2: "黄连河",
    tag2url:
      "https://baike.sogou.com/v592993.htm?fromTitle=%E9%BB%84%E8%BF%9E%E6%B2%B3",
  },
  {
    school: "",
    area: "昭通",
    tag1: "",
    tag1url: "",
    tag2: "大龙洞",
    tag2url: "https://baike.sogou.com/m/v741877.htm?ch=sogou.scenic.vr",
  },
  {
    school: "",
    area: "昭通",
    tag1: "",
    tag1url: "",
    tag2: "巧家药山",
    tag2url:
      "https://baike.sogou.com/v20678232.htm?fromTitle=%E6%98%AD%E9%80%9A%E5%B7%A7%E5%AE%B6%E8%8D%AF%E5%B1%B1",
  },
  {
    school: "",
    area: "昭通",
    tag1: "",
    tag1url: "",
    tag2: "罗炳辉将军纪念馆",
    tag2url:
      "https://baike.sogou.com/v72818412.htm?fromTitle=%E7%BD%97%E7%82%B3%E8%BE%89%E5%B0%86%E5%86%9B%E7%BA%AA%E5%BF%B5%E9%A6%86&ch=frombaikevr",
  },
  {
    school: "",
    area: "昭通",
    tag1: "",
    tag1url: "",
    tag2: "向家坝电站",
    tag2url:
      "https://baike.sogou.com/v6004327.htm?fromTitle=%E5%90%91%E5%AE%B6%E5%9D%9D%E7%94%B5%E7%AB%99&ch=frombaikevr",
  },
  {
    school: "",
    area: "昭通",
    tag1: "",
    tag1url: "",
    tag2: "小草坝",
    tag2url:
      "https://baike.sogou.com/v2030674.htm?fromTitle=%E5%B0%8F%E8%8D%89%E5%9D%9D&ch=frombaikevr",
  },
];
const result = [];

li.forEach((item) => {
  const area = item.area;
  const index = result.findIndex((obj) => obj.name === area);

  if (index === -1) {
    result.push({
      name: area,
      schools: [item.school],
      tag1s: [{ tag: item.tag1, url: item.tag1url }],
      tag2s: [{ tag: item.tag2, url: item.tag2url }],
    });
  } else {
    if (item.school) {
      result[index].schools.push(item.school);
    }
    if (item.tag1 && item.tag1url) {
      result[index].tag1s.push({ tag: item.tag1, url: item.tag1url });
    }
    if (item.tag2 && item.tag2url) {
      result[index].tag2s.push({ tag: item.tag2, url: item.tag2url });
    }
  }
});
console.log(result);
// 默认导出
export default result;
