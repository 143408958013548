
import { Options, Vue } from "vue-class-component";
// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
import vuex from "vuex";
import Ajax from "@/api/Ajax";
import Din from "@/components/Din.vue";

@Options({
  computed: {
    ...vuex.mapState(["userinfo"]),
  },
  components: {
    Din,
  },
  data() {
    return {
      li: [], // 排名列表
      // 主域名
      mainUrl: Ajax.url,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 获得列表
    async getList() {
      const reData = await Ajax.request.get("/api/User/recordLi");
      this.li = reData.data.data;
    },
    // 用户登录
    login(name: string, query = {}) {
      this.$router.replace({
        name: name,
        query,
      });
    },
  },
})
export default class Gonglue1 extends Vue {}
