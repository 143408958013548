
import { Options, Vue } from "vue-class-component";
import Ajax from "@/api/Ajax";
// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
declare const wx: any;
import vuex from "vuex";

@Options({
  computed: {
    ...vuex.mapState(["userinfo"]),
  },
  components: {},
  async mounted() {
    if (this.$route.query.token) {
      await this.tokenLogin(this.$route.query.token);
    }
    this.$router.replace({
      name: "Start",
    });
  },
  methods: {
    // 用户信息登录
    async tokenLogin(token: string) {
      const reData = await Ajax.request.post("/api/User/index", {
        token,
      });
      if (reData.data.code === 1) {
        this.$store.commit("setUserInfo", reData.data.data.userinfo);
      }
    },
    // 上传图片
    upImage() {
      wx.chooseImage({
        count: 1, // 默认9
        sizeType: ["original", "compressed"], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ["album", "camera"], // 可以指定来源是相册还是相机，默认二者都有
        success: function (res: any) {
          const localIds = res.localIds; // 返回选定照片的本地 ID 列表，localId可以作为 img 标签的 src 属性显示图片
          console.log("本地", localIds);
          wx.uploadImage({
            localId: localIds, // 需要上传的图片的本地ID，由 chooseImage 接口获得
            isShowProgressTips: 1, // 默认为1，显示进度提示
            success: function (res2: any) {
              const serverId = res2.serverId; // 返回图片的服务器端ID
              console.log("服务器ID", serverId);
              // 上传图片ID
              Ajax.request.post("/api/Pub/upimage", {
                serverId,
              });
            },
          });
        },
      });
    },
    jump(obj: any) {
      // document.getElementById("audio").play();
      this.$router.replace(obj);
    },
  },
})
export default class Home extends Vue {}
