import { createStore } from "vuex";
import Ajax from "@/api/Ajax";
import createPersistedstate from "vuex-persistedstate";
export default createStore({
  state: {
    peopleName: "豹豹",
    people: "汉服",
    din: {}, // 记录弹窗
    token: null, // 登录用的token
    // 登录
    school: null,
    // 用户信息
    userinfo: null,
  },
  mutations: {
    setPeopleName(state, peopleName) {
      state.peopleName = peopleName;
    },
    setPeople(state, people) {
      state.people = people;
    },
    clearDin(state) {
      state.din = {};
    },
    setDin(state: any, school) {
      state.din[school] = true;
    },
    // 设置学校
    setSchool(state, school) {
      state.school = school;
      console.log(school);
    },
    // 设置用户信息
    setUserInfo(state, data) {
      state.userinfo = data;
      state.token = data.token;
      console.log("写入用户信息", state);
    },
  },
  actions: {
    // 微信登录
    async wxlogin() {
      const reData = await Ajax.request.post("/api/index/wxloginAjax", {
        url: window.location.href,
      });
      // 跳转走
      window.location.replace(reData.data.data);
    },
  },
  plugins: [createPersistedstate()],
  modules: {},
});
