import axios from "axios";
import qs from "qs";

const mainUrl = "https://ynyd2022api.link-dev.com/index.php";
const request = axios.create({
  baseURL: mainUrl,
  transformRequest: [
    function (data) {
      return qs.stringify(data);
    },
  ],
  // timeout: 30000,
});

export default class Ajax {
  public static url: string = mainUrl;
  public static request = request;
}
