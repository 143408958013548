
import { Options, Vue } from "vue-class-component";
// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src

@Options({
  components: {},
  methods: {
    jump(ares: string) {
      // 判断如果是昆明就跳转到name是kunming的页面
      if (ares === "昆明") {
        this.$router.replace({
          name: "kunming",
        });
        return;
      } else {
        this.$router.replace({
          name: "schoolList",
          query: {
            ares: ares,
          },
        });
      }
    },
  },
})
export default class City extends Vue {}
