<template>
  <div>
    <router-link replace :to="{ name: 'map' }" class="backBtn">
      返回
    </router-link>
    <img
      src="@/assets/bbs/3.png"
      class="titles"
      style="animation-delay: 0.2s"
    />
    <div class="textController">
      <div class="inpuController">
        <div class="aSoInput">
          <input
            v-model="so"
            placeholder="昵称搜索"
            placeholder-class="input-placeholder"
          />
          <img @click="getData" src="@/assets/bbs/so.png" />
        </div>
        <DatePicker @on-change="handleOk" placement="left" :open="open">
          <div class="aSoInput">
            <input
              @click="open = true"
              format="YYYY-MM-DD"
              readonly
              :value="date"
              placeholder="日期搜索"
              placeholder-class="input-placeholder"
            />
            <img @click="getData" src="@/assets/bbs/so.png" />
          </div>
        </DatePicker>
      </div>
      <div class="sollerContoler">
        <div class="atext" v-for="(val, ind) in li" :key="ind">
          <div class="nickname">
            <div>{{ val.nickname }}</div>
            <div class="flex1"></div>
            <img
              @click="like(ind)"
              class="xinxin"
              :class="val.liked ? '' : 'hui'"
              src="@/assets/bbs/xinxin.png"
            />
            <div @click="like(ind)" class="numer">{{ val.lk }}</div>
          </div>
          <div class="sanjiao"></div>
          <div class="text">
            <img class="fj" src="@/assets/bbs/fj.png" />
            <img class="pl" src="@/assets/bbs/pl.png" />
            <div class="textdata">
              {{ val.text }}
            </div>
            <div v-if="val.img">
              <Image
                :src="val.img"
                width="100px"
                :preview-list="[val.img]"
                preview
              />
            </div>
          </div>
        </div>
      </div>

      <img
        @click="openForm"
        src="@/assets/bbs/btn.png"
        class="btns"
        style="animation-delay: 0.2s"
      />
    </div>
    <!--一个el-form模态框，其中有昵称、文字、图片三个表单组件-->
    <Modal title="发表消息" v-model="modal" width="80%" @on-ok="submit">
      <Form label-position="top" :model="form">
        <FormItem label="昵称">
          <Input v-model="form.nickname"></Input>
        </FormItem>
        <FormItem label="文字">
          <Input type="textarea" v-model="form.text"></Input>
        </FormItem>
        <FormItem label="图片">
          <Upload
            v-if="!form.img"
            :on-success="upSuccess"
            action="https://ynyd2022api.link-dev.com/index.php/api/Bbs/upimage"
          >
            <Button icon="ios-cloud-upload-outline">上传图片</Button>
          </Upload>
          <Image
            v-else
            :src="form.img"
            width="100px"
            :preview-list="[form.img]"
            preview
          />
        </FormItem>
      </Form>
    </Modal>
    <Din type="jihedi">
      <img src="@/assets/D/6/1.webp" />
      <img src="@/assets/D/8.png" />
    </Din>
  </div>
</template>
<script setup>
import { getCurrentInstance } from "vue";
const { proxy } = getCurrentInstance();
import Ajax from "@/api/Ajax";
import Din from "@/components/Din.vue";
// 声明周期函数加载完成后
import { onMounted } from "vue";
// 引入ref
import { ref } from "vue";
// 创建一个表单，有昵称、文字信息、图片
const form = ref({
  nickname: "",
  text: "",
  img: "",
});
const so = ref("");
// 时间选择器
const open = ref(false);
// 时间
const date = ref("");
// 控制模态框开关
const modal = ref(false);
const li = ref([]); // 数据列表
// 打开弹窗
function openForm() {
  // 重置表单
  form.value = {
    nickname: "",
    text: "",
    img: "",
  };
  modal.value = true;
  console.log("打开弹窗");
}
function handleOk(e) {
  console.log(e);
  date.value = e;
}
// 调用接口/api/Bbs/li查询数据
async function getData() {
  const res = await Ajax.request.get("/api/Bbs/li", {
    params: {
      so: so.value,
      date: date.value,
    },
  });
  li.value = res.data.data.map((val) => {
    // 从缓存中读取是否已经点赞
    const isLiked = localStorage.getItem(val.id);
    if (isLiked) val.liked = true;
    else val.liked = false;
    return val;
  });
}
onMounted(() => {
  // 从缓存中读取是否已经点赞
  getData();
});
async function submit() {
  // 向/api/Bbs/add提交数据，存在错误就弹窗
  const res = await Ajax.request.post("/api/Bbs/add", form.value);
  const code = res.data.code;
  if (code === 1) {
    // 关闭弹窗
    modal.value = false;
    getData();
    proxy.$Message.success("发表成功");
  } else {
    // 提示错误
    proxy.$Message.error(res.data.msg);
  }
}
// 上传完成钩子
function upSuccess(response) {
  form.value.img = response.data;
}
// 喜欢一个记录
function like(ind) {
  // 从换从中判断是否已经点击过了
  const isLiked = localStorage.getItem(li.value[ind].id);
  if (isLiked) {
    // proxy.$Message.error("您已经点过赞了");
    return;
  }
  // lk加一
  const val = li.value[ind];
  val.lk++;
  // 改变liked
  val.liked = true;
  // 根据ID写入缓存中，下次点击的时候用来判断是否点击过了
  localStorage.setItem(val.id, 1);
  // 调用like接口传id进去
  Ajax.request.post("/api/Bbs/like", { id: val.id });
}
// 引入 router
// import { useRouter } from "vue-router";
// const router = useRouter();
</script>
<style lang="less" scoped>
.titles {
  width: 100%;
}
.listController {
  margin: auto;
  width: 220px;
  margin-top: -15%;
  height: calc(100vh - 230px);
  overflow-y: scroll;
  .name {
    background-color: #7dccf3;
    font-size: 20px;
    color: white;
    font-weight: bold;
    width: 220px;
    text-align: center;
    border-radius: 5px;
    // 除了最后一个，下边距10px
    padding: 5px 0px;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

@total-classes: 20; // 要生成的类的总数
@delay: 0.1s; // 动画延迟
.generate-classes(@i) when (@i > 0) {
  .generate-classes(@i - 1); // 递归调用，生成下一个类

  @class-name: ~"delay1-@{i}"; // 生成类名，如 "delay-1", "delay-2" 等

  .@{class-name} {
    animation-delay: (@i * @delay); // 设置动画延迟
  }
}
.generate-classes(@total-classes); // 开始生成类
// 消息推送
.textController {
  width: calc(100% - 40px);
  margin: auto;
  height: calc(100vh - 66vw);
  border-radius: 20px;
  margin-top: -10px;
  // 背景图bj
  background-image: url("../assets/bbs/bj.png");
  // size 100%
  background-size: 100%;
  padding: 20px;
  padding-top: 10px;
  // 竖向剧中flex
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .inpuController {
    // felx布局居右
    display: flex;
    justify-content: flex-end;
    width: 100%;
    .aSoInput {
      margin-left: 10px;
      display: flex;
      align-items: center;
      // 蓝色边框圆角
      border: 1px solid #0077c3;
      border-radius: 20px;
      overflow: hidden;
      background-color: #0077c3;
      input {
        height: 20px;
        // 去边框
        border: 0px none #0077c3;
        width: 70px;
        text-align: center;
        font-size: 12px;
        // 去了输入的时候的选中边框
        outline: none;
      }
      img {
        width: 15px;
        margin-left: 5px;
      }
    }
  }
  .sollerContoler {
    overflow-y: scroll;
    flex: 1;
    width: 100%;
    margin-top: 5px;
  }
  .atext {
    // 画一个三角
    .nickname {
      color: #000;
      font-size: 16px;
      font-weight: bold;
      margin-top: 10px;
      display: flex;
      align-items: center;
      .flex1 {
        flex: 1;
      }
      .xinxin {
        width: 20px;
      }
      .numer {
        margin-left: 10px;
        color: #7a7a7a;
      }
    }
    .sanjiao {
      width: 0;
      height: 0;
      border-top: 15px solid white;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      // 旋转180度
      transform: rotate(180deg);
      margin-left: 10px;
    }
    .text {
      background-color: #fff;
      border-radius: 10px;
      padding: 10px;
      font-size: 14px;
      color: #000;
      // 文字支持换行
      word-break: break-all;
      word-wrap: break-word;
      position: relative;
      .textdata {
        position: relative;
        z-index: 1;
      }
      .fj {
        position: absolute;
        width: 60px;
        right: 0px;
        top: -10px;
      }
      .pl {
        position: absolute;
        width: 60px;
        bottom: -10px;
        left: 0px;
      }
    }
  }
  .btns {
    width: 50%;
    display: block;
    margin: auto;
    margin-top: 20px;
  }
}
// 写一个CSS把彩色突变成灰色
.hui {
  filter: grayscale(100%);
}
</style>
